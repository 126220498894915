import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { PLAYGROUND_ITEMS, LEARN_ITEMS } from "config/header";

import DropdownItem from "./Dropdown/DropdownItem";

const MobileHeader = ({ currentPathname = "" }) => {
  const [showPerspective, togglePerspective] = useState(false);
  const headerRef = useRef(null);
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        togglePerspective(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    togglePerspective(false);
  }, [pathname, hash]);

  return (
    <div className="lg:hidden fixed bottom-4 left-0 right-0 flex items-center justify-center z-50 backdrop-blur-[6px] mx-auto w-[calc(100vw-2.5rem)] rounded-[18px]">
      <nav
        ref={headerRef}
        className="w-[calc(100vw-2.5rem)] px-5 py-2 rounded-[18px] border-2 border-[#262525] text-[#707070] bg-[rgba(18,18,18,0.86)]"
      >
        <Fade duration={500}>
          <div
            className={`${
              showPerspective
                ? "max-h-[calc(100vh-8rem)] overflow-y-scroll overflow-x-hidden border-b-2 border-[#262525] mx-[-1.25rem]"
                : "max-h-0 hidden"
            } transition-all duration-200 pt-6`}
          >
            <div className="px-5">
              <h4 className="text-white text-sm font-bold mb-2">PLAYGROUND</h4>
              <DropdownItem items={PLAYGROUND_ITEMS} />
              <Link to="/articles">
                <h4 className="text-white text-sm font-bold mt-6 mb-2">LEARN &rsaquo;</h4>
              </Link>
              <DropdownItem items={LEARN_ITEMS} />
            </div>
          </div>
        </Fade>
        <div
          className={`flex items-center justify-between duration-200 transition-all ${showPerspective ? "pt-2" : ""} `}
        >
          <Link
            to="/"
            onClick={() => togglePerspective(false)}
            className={`flex items-center flex-col justify-center duration-200 flex-grow-0 flex-shrink-0 w-14 ${
              currentPathname === "" && !showPerspective ? "" : "rekursiv-grayscale"
            }`}
          >
            <img className="mb-1 h-5" src={require("assets/icons/navbar/home.svg").default} alt="Icon" />
            <p
              className={`text-[10px] text-[#A3A3A3] ${
                currentPathname === "" && !showPerspective ? "text-rekursiv-red" : ""
              }`}
            >
              Home
            </p>
          </Link>
          {/* <div className="flex items-center flex-col justify-center flex-grow-0 flex-shrink-0 w-14">
              <img className="mb-1 h-5" src={require("assets/icons/navbar/about.svg").default} alt="Icon" />
              <p className="text-[10px] text-[#707070]">About</p>
            </div> */}
          <Link
            to="/work"
            onClick={() => togglePerspective(false)}
            className={`flex items-center flex-col justify-center duration-200 flex-grow-0 flex-shrink-0 w-14 ${
              currentPathname === "work" && !showPerspective ? "" : "rekursiv-grayscale"
            }`}
          >
            <img className="mb-1 h-5" src={require("assets/icons/navbar/work.svg").default} alt="Icon" />
            <p
              className={`text-[10px] text-[#A3A3A3] ${
                currentPathname === "work" && !showPerspective ? "text-rekursiv-red" : ""
              }`}
            >
              Work
            </p>
          </Link>
          <Link
            to="/services"
            onClick={() => togglePerspective(false)}
            className={`flex items-center flex-col justify-center duration-200 flex-grow-0 flex-shrink-0 w-14 ${
              currentPathname === "services" && !showPerspective ? "" : "rekursiv-grayscale"
            }`}
          >
            <img className="mb-1 h-5" src={require("assets/icons/navbar/services.svg").default} alt="Icon" />
            <p
              className={`text-[10px] text-[#A3A3A3] ${
                currentPathname === "services" && !showPerspective ? "text-rekursiv-red" : ""
              }`}
            >
              Services
            </p>
          </Link>
          <div
            onClick={() => togglePerspective(!showPerspective)}
            className={`flex items-center flex-col justify-center duration-200 flex-grow-0 flex-shrink-0 w-14 ${
              currentPathname === "articles" || showPerspective ? "" : "rekursiv-grayscale"
            }`}
          >
            <img className="mb-1 h-5" src={require("assets/icons/navbar/perspective.svg").default} alt="Icon" />
            <p
              className={`text-[10px] text-[#A3A3A3] ${
                currentPathname === "articles" || showPerspective ? "text-rekursiv-red" : ""
              }`}
            >
              Perspective
            </p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MobileHeader;
