import { LIST_UXD, LIST_UXR, LIST_DATA_RESEARCH, LIST_OFFLINE } from "./work";

export const UXR_SERVICES = [
  {
    title: "Gain Deeper Insights",
    caption: "Explore new directions, develop understanding of user needs & uncover opportunity.",
    subHeadingContent: "In Depth Interview; Ethnography Research; Focus Group Discussion",
  },
  {
    title: "Analyze User Behavior",
    caption:
      "Understanding on how users interact with a product to gain insights into their actions, preferences, & patterns.",
    subHeadingContent: "Persona, Journey Mapping, Storyboard, Card Sorting, Heatmap Analysis, etc.",
  },
  {
    title: "Validate Our Product",
    caption:
      "Evaluating and enhancing the user experience of a product through thorough assessment and actionable insights.",
    subHeadingContent: "Usability Testing, Concept Testing, A/B Testing, First Click Test, etc",
  },
  {
    title: "Audit Product Experience",
    caption: "Explore new directions, develop understanding of user needs & uncover opportunity.",
    subHeadingContent: "360˚ UX Audit,Usability Testing, Heuristic Evaluation, etc",
  },
  {
    title: "Optimize Performance",
    caption: "Making improvements & enhancements to ensure it achieves desired outcomes.",
    subHeadingContent: "Data Analytics, Feedback Survey Analysis, Quality Assurance, Continuous Evaluation",
  },
];

export const DATA_RESEARCH = [
  {
    title: "Analysis 360˚ Data",
    caption: "Analyzing diverse data sources to gain comprehensive insights for informed decision-making.",
  },
  {
    title: "Google Analytic Analysis",
    caption:
      "In-depth analysis of your website's performance & user behavior using Google Analytics data to uncover insights and optimize your digital strategy.",
  },
  {
    title: "Predictive Modelling",
    caption: "Forecast future outcomes or behaviors based on historical data patterns and trends.",
    subHeading: "Implementation",
    subHeadingContent:
      "Persona segmentation, Personalization, Predicting user behavior or satisfaction, Recommendation systems",
  },
];

export const GROWTH_EXPERIENCES_STRATEGY = [
  {
    title: "Ideation & Innovation",
    caption:
      "Generating creative ideas, exploring possibilities, developing new & innovative solutions to address challenges or opportunities.",
  },
  {
    title: "Optimize Customer Experience",
    caption:
      "Enhancing customer experience on digital touch points through improved interfaces, personalization, journeys, & interactions.",
  },
  {
    title: "Deliver Service Design",
    caption:
      "Aims to create seamless experiences from digital to offline for customers encompassing processes, touch points, & interactions.",
  },
  {
    title: "Develop UX Masterplan",
    caption: "Comprehensive digital growth strategy aligned with business goals, market dynamics, and customer needs.",
  },
  {
    title: "Grow & Scale Up the Product",
    caption: "Strategic process of expanding and maximizing the reach, impact, and profitability of a digital product.",
  },
  {
    title: "Drive Digital Transformation",
    caption:
      "Assisting organizations in reimagining business processes, adopting new technologies, or fostering a culture of innovation & agility.",
  },
];

export const EXPERIENCE_DESIGN = [
  {
    title: "Create Product",
    caption:
      "Conceptualizing & designing products or system that meet the needs of users, business, and technology capability.",
  },
  {
    title: "Redesign Product",
    caption:
      "Reimagining & transforming an existing product or system to improve its user experience, functionality, & performance.",
  },
  {
    title: "Design New Feature",
    caption: "Designing and implementing innovative features to elevate user experience across products and services.",
  },
  {
    title: "Iterative Design",
    caption: "Continuously refine and iterate on designs through prototyping and user feedback.",
  },
];

export const OFFLINE_EXPERIENCE_ENHANCEMENT = [
  {
    title: "Interactive Offline Activity",
    caption:
      "Develop a concept of engaging & interactive experiences for your offline event or campaign (e.g. digital games or activity).",
  },
  {
    title: "Gamification Concept",
    caption:
      "Concepting gamification by incorporating tailored elements & mechanics based on your needs or challenges.",
  },
];

export const LIST_PLATFORM = [
  {
    title: "Event Interactive",
    image: require("assets/services/event-interactive.png"),
  },
  {
    title: "Kiosks",
    image: require("assets/services/kiosks.png"),
  },
  {
    title: "Vending Machine",
    image: require("assets/services/vending-machine.png"),
  },
  {
    title: "ATM",
    image: require("assets/services/atm.png"),
  },
  {
    title: "Wearables",
    image: require("assets/services/wearables.png"),
  },
  {
    title: "Other IoT",
    image: require("assets/services/iot.png"),
  },
  {
    title: "Website",
    image: require("assets/services/website.png"),
  },
  {
    title: "Application",
    image: require("assets/services/application.png"),
  },
  {
    title: "System",
    image: require("assets/services/system.png"),
  },
  {
    title: "Gamification",
    image: require("assets/services/gamification.png"),
  },
  {
    title: "Web AR",
    image: require("assets/services/ar.png"),
  },
  {
    title: "Console",
    image: require("assets/services/console.png"),
  },
];

export const SERVICE_NAME_MAPPING = {
  "user-experience-research": "uxr",
  "experience-design": "uxd",
  "growth-experience-strategy": "growth",
  "offline-experience-enhancement": "offline",
  "data-research": "data",
};

export const LIST_SERVICES = [
  {
    image: require("assets/services/list/User Experience Research.svg").default,
    title: "User Experience Research",
    to: "/services/detail/user-experience-research",
    cardClassName: "bg-[#691716] text-white",
    key: "uxr",
  },
  {
    image: require("assets/services/list/Experience Design.svg").default,
    title: "Experience Design",
    to: "/services/detail/experience-design",
    cardClassName: "bg-[#9C1D16] text-white",
    key: "uxd",
  },
  {
    image: require("assets/services/list/Growth & Experience Strategy.svg").default,
    title: "Growth & Experience Strategy",
    to: "/services/detail/growth-experience-strategy",
    cardClassName: "bg-[#BD3029] text-white",
    key: "growth",
  },
  {
    image: require("assets/services/list/Offline Experience Enhancement.svg").default,
    title: "Offline Experience Enhancement",
    to: "/services/detail/offline-experience-enhancement",
    cardClassName: "bg-[#FEBCB4] text-rekursiv-red",
    buttonClassName: "border-rekursiv-red",
    key: "offline",
  },
  {
    image: require("assets/services/list/Data Research.svg").default,
    title: "Data Research",
    to: "/services/detail/data-research",
    cardClassName: "bg-rekursiv-white text-rekursiv-red",
    buttonClassName: "border-rekursiv-red",
    key: "data",
  },
];

// Output Section
export const UXR_OUTPUT = [
  {
    icon: "🫀",
    text: "Deep understanding of user needs, behaviors, and preferences",
  },
  {
    icon: "🛠",
    text: "Identification of usability issues and areas for improvement",
  },
  {
    icon: "🧠",
    text: "Insights into user motivations, pain points, and goals",
  },
  {
    icon: "🛣",
    text: "Data-driven optimization of user flows and interactions",
  },
  {
    icon: "💻",
    text: "Validation of design decisions through user feedback and testing",
  },
  {
    icon: "💡",
    text: "Identification of opportunities for innovation & differentiation",
  },
  {
    icon: "👩🏻‍🔧",
    text: "Creation of user personas and user journey mapping",
  },
];

export const UXD_OUTPUT = [
  {
    icon: "👩‍🔬",
    text: `All output from <span id="replace">User Experience Research</span> services`,
  },
  {
    icon: "🔍",
    text: "Analyze and Identify the best possible solution to the problem",
  },
  {
    icon: "🧬",
    text: "Structured and organized information architecture",
  },
  {
    icon: "📱",
    text: "Creation of wireframes & interactive prototypes to visualize",
  },
  {
    icon: "🎨",
    text: "Development of visually appealing designs",
  },
];

export const DATA_OUTPUT = [
  {
    icon: "📊",
    text: "Data-driven insights and analysis",
  },
  {
    icon: "🧶",
    text: "Identification of trends, patterns, and correlations within the data",
  },
  {
    icon: "🧮",
    text: "Data visualizations to support decision-making",
  },
  {
    icon: "📚",
    text: "Solution strategy for data-driven decision-making",
  },
  {
    icon: "📑",
    text: "Comprehensive report  with insights and data visualization",
  },
];

export const OFFLINE_OUTPUT = [
  {
    icon: "🪄",
    text: "Innovative strategies to enhance in-person experiences",
  },
  {
    icon: "📟",
    text: "Integration of digital and physical touch points journey",
  },
  {
    icon: "🛣",
    text: "Optimization of customer flow within physical spaces",
  },
  {
    icon: "👾",
    text: "Concept design of interactive installations or gamification",
  },
  {
    icon: "📑",
    text: "Evaluation and improvement report",
  },
];

export const GROWTH_OUTPUT = [
  {
    icon: "💡",
    text: "Identification of opportunities for innovation & differentiation",
  },
  {
    icon: "🚙",
    text: "Customer journey map of the different touchpoints",
  },
  {
    icon: "📝",
    text: "Strategic plans for business growth",
  },
  {
    icon: "🚥",
    text: "Roadmaps and action plans to optimize user experiences and drive growth",
  },
  {
    icon: "📈",
    text: "A set of metrics for measuring success",
  },
];

// FAQ Section
export const UXR_FAQ = [
  {
    title: "Why Rekursiv for UX Research?",
    content: `
      <div className="text-[#BFBFBF]">
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>Listening to your business needs:</b> We prioritize understanding your business goals,
            challenges, and requirements to establish a strong partnership and ensure our solutions
            align with your vision.
          </li>
          <li className="mb-4">
            <b>Data-driven approach:</b> Our comprehensive research methodology combines quantitative
            and qualitative data, enabling us to gather holistic insights and provide well-informed
            recommendations.
          </li>
          <li className="mb-4">
            <b>Active & collaborative:</b> Collaboration is at the core of our process. We actively
            engage your team throughout the research journey, guiding and supporting you at every step.
          </li>
          <li className="mb-4">
            <b>Providing comprehensive insights:</b> Our research goes beyond surface-level findings. We
            delve deep into the data to uncover meaningful insights that have a direct impact on your
            business.
          </li>
          <li>
            <b>Actionable solutions:</b> We don't stop at insights; we translate them into actionable
            solutions. Our recommendations are practical, tailored to your specific context, and
            designed to drive tangible results.
          </li>
        </ol>
        <br />
        Through our attentive listening, data-driven approach, collaborative mindset, comprehensive
        insights, and actionable solutions, we strive to deliver exceptional value and drive the success
        of your business.
      </div>
    `,
  },
  {
    title: "Range of UX Research Methods Available",
    content: `
      <div className="text-[#BFBFBF]">
        A range of UX research methods is available to suit different objectives and contexts. The
        selection of specific research methods depends on the goals of the project, the target audience,
        and the available resources.
        <br /><br />
        <b>But here is available research methods in Rekursiv:</b>
        <br />
        Usability Testing, Participatory Design, Ethnography Study, Focus Group, In Depth Interview,
        Eyetracking, Benchmarking, Concept Testing, Diary Studies, Customer Feedback Analysis, Card
        Sorting, Clickstream Analysis, A/B Testing, Analytics Review
      </div>
    `,
  },
  {
    title: "What are the key benefits of conducting UX research?",
    content: `
      <div className="text-[#BFBFBF]">
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>User Insights:</b> UX research helps to gain a deep understanding of user needs,
            behaviors, and preferences, providing valuable insights for informed decision-making.
          </li>
          <li className="mb-4">
            <b>Usability Improvement:</b> By identifying usability issues and pain points through
            research, UX research allows for targeted improvements in design and functionality,
            enhancing the overall user experience.
          </li>
          <li className="mb-4">
            <b>Enhanced User Satisfaction:</b> By focusing on user-centered design and incorporating
            user feedback, UX research helps create products and services that meet user expectations,
            leading to increased satisfaction and loyalty.
          </li>
          <li className="mb-4">
            <b>Increased Engagement:</b> Understanding user motivations and behaviors enables the
            creation of engaging experiences that captivate and retain users, fostering higher levels of
            engagement with the product or service.
          </li>
          <li>
            <b>Business Success:</b> Effective UX research aligns user needs with business goals,
            leading to improved customer acquisition, retention, and overall business success
          </li>
        </ol>
        <br />
        <b>In summary,</b> the key benefits of conducting UX research include gaining valuable insights
        into user behavior and preferences, identifying usability issues and pain points, improving
        product design and functionality, enhancing user satisfaction and engagement, and ultimately
        driving business success through better user experiences.
      </div>
    `,
  },
  {
    title: "What is the difference between UX research and UX Design?",
    content: `
      <div className="text-[#BFBFBF]">
        <b>UX research provides the foundation of knowledge and user insights, UX design</b> builds upon
        that knowledge to <b>create the actual design solutions</b>. Both disciplines work
        collaboratively throughout the design process to deliver user-centered and effective
        experiences.
        <br />
        <br />
        <p>
          <b>Focus:</b>
        </p>
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            UX research is primarily concerned with understanding users, their behaviors, needs,
            motivations, and preferences;
          </li>
          <li>
            UX design concentrates on creating meaningful and enjoyable user experiences based on the
            insights and findings obtained through UX research.
          </li>
        </ol>
        <br />
        <p>
          <b>Purpose:</b>
        </p>
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">UX research is to gather insights & data through various research methods</li>
          <li>UX design is to translate research insights into tangible solutions.</li>
        </ol>
        <br />
        <p>
          <b>Outputs:</b>
        </p>
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            UX research include user personas, user journey maps, usability test findings, research
            reports, and actionable recommendations
          </li>
          <li>UX design include wireframes, prototypes, visual designs, and design specifications.</li>
        </ol>
      </div>
    `,
  },
  {
    title: "How long does a typical UX research project take?",
    content: `
      <div className="text-[#BFBFBF]">
        The duration of a typical UX research project varies depending on the <b>scope, complexity,</b> and <b>objectives</b> of the project.<br /><br />
        While timelines can vary, a UX research project typically spans <b>several weeks</b> to a <b>few months</b>. This timeframe includes activities such as planning, participant recruitment, data collection, analysis, and reporting.
        Factors such as the <b>number of research methods</b> used, the <b>size of the target audience</b>, and the <b>availability of resources</b> can influence project duration.<br /><br />
        At Rekursiv, we work closely with our clients to <b>establish realistic timelines and ensure efficient project delivery without compromising the quality</b> of research insights.
      </div>
    `,
  },
  {
    title: "How much does UX research cost?",
    content: `
      <div className="text-[#BFBFBF]">
        The cost of UX research varies depending on the <b>scope, complexity,</b> and <b>specific requirements</b> of the project.<br /><br />
        Factors such as the number of research participants, research methods employed, and the level of analysis and reporting needed all influence the overall cost. It is recommended to consult with us to discuss your specific project needs and obtain a tailored cost estimate.
      </div>
    `,
  },
  {
    title: "Is this service only works for digital products (app, website)?",
    content: `
      <div className="text-[#BFBFBF]">
        No, our services are not limited to digital products such as apps and websites. We also work with physical products, gamification, and immersive technologies like AR and VR.<br /><br />
        Our expertise extends across various mediums, allowing us to provide comprehensive user experience solutions for a wide range of products and technologies.
      </div>
    `,
  },
  {
    title: "Example of your reports",
    content: `
      <div className="text-[#BFBFBF]">
        Certainly! To provide you with examples of our reports, please reach out to us.<br /><br />
        We would be happy to showcase our comprehensive and insightful reports that highlight our research findings, methodologies, and actionable recommendations.
      </div>
    `,
  },
];

export const UXD_FAQ = [
  {
    title: "Why Rekursiv for UX Design?",
    content: `
      <div className="text-[#BFBFBF]">
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>Listening to your business needs:</b> We prioritize understanding your business goals,
            challenges, and requirements to establish a strong partnership and ensure our solutions
            align with your vision.
          </li>
          <li className="mb-4">
            <b>Data-driven approach:</b> Our comprehensive research methodology combines quantitative
            and qualitative data, enabling us to gather holistic insights and provide well-informed
            recommendations.
          </li>
          <li className="mb-4">
            <b>Active & collaborative:</b> Collaboration is at the core of our process. We actively
            engage your team throughout the research journey, guiding and supporting you at every step.
          </li>
          <li className="mb-4">
            <b>Providing comprehensive insights:</b> Our research goes beyond surface-level findings. We
            delve deep into the data to uncover meaningful insights that have a direct impact on your
            business.
          </li>
          <li>
            <b>Actionable solutions:</b> We don't stop at insights; we translate them into actionable
            solutions. Our recommendations are practical, tailored to your specific context, and
            designed to drive tangible results.
          </li>
        </ol>
        <br />
        Through our attentive listening, data-driven approach, collaborative mindset, comprehensive
        insights, and actionable solutions, we strive to deliver exceptional value and drive the success
        of your business.
      </div>
    `,
  },
  {
    title: "What are the key benefits of conducting UX research?",
    content: `
      <div className="text-[#BFBFBF]">
        <ol className="list-disc list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>Increased customer satisfaction:</b> When users have a positive experience with a product or service, they are more likely to be satisfied with it.
          </li>
          <li className="mb-4">
            <b>Improved brand reputation:</b> A good user experience can help to improve a brand's reputation and make it more appealing to customers.
          </li>
          <li className="mb-4">
            <b>Increased sales:</b> A good user experience can lead to increased sales by making it easier for users to find what they are looking for and complete their purchases.
          </li>
          <li>
            <b>Reduced costs:</b> A good user experience can help to reduce costs by reducing the need for customer support and making it easier for users to self-service.
          </li>
        </ol>
      </div>
    `,
  },
  {
    title: "What is the difference between UX research and UX Design?",
    content: `
      <div className="text-[#BFBFBF]">
        UX research focuses on <b>understanding user needs, behaviors, and preferences</b> through methods such as user interviews, usability testing, and surveys. It aims to gather insights to inform design decisions.<br /><br />
        UX design involves <b>creating intuitive and user-friendly interfaces, wireframes, and prototypes based on the findings from research</b>. It focuses on designing the overall user experience and ensuring that the product meets user needs effectively.
      </div>
    `,
  },
  {
    title: "How long does a typical UX design project take?",
    content: `
      <div className="text-[#BFBFBF]">
        The duration of a typical UX research project varies depending on the <b>scope, complexity,</b>
        and <b>objectives</b> of the project.
        <br />
        <br />
        <p>Here are some examples of how long different types of UX design projects might take:</p>
        <ol className="list-disc list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>A small website redesign:</b> This type of project could take anywhere from a few weeks
            to a month.
          </li>
          <li className="mb-4">
            <b>A mobile app:</b> This type of project could take anywhere from a month to several
            months.
          </li>
          <li>
            <b>A complex software application:</b> This type of project could take several months to a
            year.
          </li>
        </ol>
        <br />
        It is important to note that these are just estimates. The actual length of a UX design project,
        please reach out to us.
      </div>
    `,
  },
  {
    title: "How much does UX design cost?",
    content: `
      <div className="text-[#BFBFBF]">
        The cost of UX research varies depending on the <b>scope, complexity,</b> and <b>specific requirements</b> of the project.<br /><br />
        Factors such as the number of research participants, research methods employed, and the level of analysis and reporting needed all influence the overall cost. It is recommended to consult with us to discuss your specific project needs and obtain a tailored cost estimate.
      </div>
    `,
  },
  {
    title: "Is this service only works for digital products (app, website)?",
    content: `
      <div className="text-[#BFBFBF]">
        No, our services are not limited to digital products such as apps and websites. We also work with physical products, gamification, and immersive technologies like AR and VR.<br /><br />
        Our expertise extends across various mediums, allowing us to provide comprehensive user experience solutions for a wide range of products and technologies.
      </div>
    `,
  },
  {
    title: "Example of your reports",
    content: `
      <div className="text-[#BFBFBF]">
        Certainly! To provide you with examples of our experience design strategy, please reach out to us. Meanwhile, you can check <span id="replace">our work sections</span> to learn more.
      </div>
    `,
  },
];

export const DATA_FAQ = [
  {
    title: "How can data research help my business make informed decisions?",
    content: `
      <div className="text-[#BFBFBF]">
        Data research helps your business make informed decisions by analyzing relevant data, identifying trends, patterns, and insights to gain a deeper understanding of your customers, market dynamics, and business performance.
      </div>
    `,
  },
  {
    title: "What statistical analysis techniques do you employ to derive insights from the data?",
    content: `
      <div className="text-[#BFBFBF]">
        We use advanced statistical analysis techniques, such as regression analysis, clustering, and hypothesis testing, to derive meaningful insights from the data and identify significant relationships and patterns.
      </div>
    `,
  },
  {
    title: "What types of data sources do you utilize in your research?",
    content: `
      <div className="text-[#BFBFBF]">
        We utilize a variety of data sources, including customer surveys, user behavior analytics, social media data, market research data, and internal business data, to ensure comprehensive and accurate research findings.
      </div>
    `,
  },
  {
    title: "How do you ensure the accuracy and reliability of the collected data?",
    content: `
      <div className="text-[#BFBFBF]">
        We employ rigorous data collection methods, maintain data quality controls, and conduct data validation processes to ensure the accuracy and reliability of the collected data.
      </div>
    `,
  },
  {
    title: "How long does a typical data research project take to complete?",
    content: `
      <div className="text-[#BFBFBF]">
        The duration of a data research project depends on its complexity and scope. However, most projects are completed within a few weeks to a few months.
      </div>
    `,
  },
  {
    title: "What is the cost structure for data research services?",
    content: `
      <div className="text-[#BFBFBF]">
        Our cost structure is tailored to each project's requirements. We provide customized quotes based on factors such as the research scope, data sources, and analysis complexity.
      </div>
    `,
  },
  {
    title: "Can you provide examples of how data research has driven actionable outcomes for previous clients?",
    content: `
      <div className="text-[#BFBFBF]">
        Certainly! In the past, our data research has helped clients optimize their marketing strategies, identify new product opportunities, enhance customer experiences, and improve operational efficiencies.<br /><br />
        To provide you with examples of our reports, please reach out to us.<br />
        Meanwhile, you can check <span id="replace">our work sections</span> to learn more.
      </div>
    `,
  },
];

export const OFFLINE_FAQ = [
  {
    title: "What is the significance of enhancing offline experiences in today's digital age?",
    content: `
      <div className="text-[#BFBFBF]">
      It offers a unique opportunity to engage customers in a tangible and memorable way.<br /><br />
      While digital experiences are prevalent, the offline environment allows for personal interactions, sensory engagement, and emotional connections that can set businesses apart & create lasting impressions.</div>
    `,
  },
  {
    title: "What techniques or methodologies do you use to conceptualize and prototype the enhancements?",
    content: `
      <div className="text-[#BFBFBF]">
        We utilize a range of techniques and methodologies to conceptualize and prototype offline experience enhancements. This may involve conducting user research, creating user journey maps, employing design thinking methodologies, and using rapid prototyping techniques to test and refine ideas.
      </div>
    `,
  },
  {
    title: "How do you ensure that the enhancements align with our brand and customer expectations?",
    content: `
      <div className="text-[#BFBFBF]">
        We ensure that the enhancements align with your brand and customer expectations by conducting a thorough analysis of your brand values, target audience, and customer insights.<br /><br />
        We involve your team in the design process, gather feedback, and iterate on concepts to collaborate and ensure alignment with your brand identity and customer expectations.
      </div>
    `,
  },
  {
    title: "What is the expected timeline for implementing the proposed enhancements?",
    content: `
      <div className="text-[#BFBFBF]">
        The expected timeline for implementing the proposed enhancements varies depending on the scope and complexity of the project. We work closely with you to define project milestones and establish a realistic timeline that takes into account factors such as research, ideation, design, development, and implementation.
      </div>
    `,
  },
  {
    title: "How do you measure the impact of the offline experience enhancements?",
    content: `
      <div className="text-[#BFBFBF]">
        We measure the impact of the offline experience enhancements through a combination of quantitative and qualitative measures.<br /><br />
        This may include tracking customer satisfaction metrics, analyzing sales data, conducting post-implementation surveys, and gathering feedback from stakeholders.
      </div>
    `,
  },
  {
    title: "Do you offer ongoing support and optimization for the offline experience enhancements?",
    content: `
      <div className="text-[#BFBFBF]">
        Yes, we offer ongoing support and optimization for the offline experience enhancements. We understand that customer expectations and market dynamics evolve, and we provide assistance in monitoring and optimizing the enhancements to ensure their continued effectiveness and alignment with your business objectives.
      </div>
    `,
  },
  {
    title: "Can you provide examples of successful offline experience enhancement projects you have undertaken?",
    content: `
      <div className="text-[#BFBFBF]">
        Absolutely! We have undertaken various successful offline experience enhancement projects. Some examples include revitalizing retail store layouts to improve customer flow, creating immersive and interactive museum exhibits, and etc.<br /><br />
        To provide you with examples of our reports, please reach out to us.<br />
        Meanwhile, you can check <span id="replace">our work sections</span> to learn more.
      </div>
    `,
  },
];

export const GROWTH_FAQ = [
  {
    title: "How can a growth and experience strategy benefit my business?",
    content: `
      <div className="text-[#BFBFBF]">
        <ol className="list-decimal list-outside pl-6">
          <li className="mt-2 mb-4">
            <b>Competitive Advantage:</b> A well-executed strategy by delivering unique and exceptional experiences, making it more attractive to customers and differentiating your brand in the market.
          </li>
          <li className="mb-4">
            <b>Informed Decision Making:</b> A strategy based on research and data enables informed decision making, reducing risks and increasing the likelihood of successful outcomes.
          </li>
          <li className="mb-4">
            <b>Adaptability to Market Changes:</b> A well-defined strategy provides a framework for monitoring market trends and customer needs, allowing your business to adapt and stay ahead of evolving market dynamics.
          </li>
          <li>
            <b>Long-Term Sustainability:</b> A strategy focused on sustainable growth fosters long-term success by building strong customer relationships, maintaining profitability, and navigating changing market conditions.
          </li>
        </ol>
      </div>
    `,
  },
  {
    title: "What industries do you specialize in for growth and experience strategy services?",
    content: `
      <div className="text-[#BFBFBF]">
        We specialize in providing growth and experience strategy services across various industries, including technology, e-commerce, finance, healthcare, travel, media, education, automotive, consumer goods, and professional services. Our expertise extends to both B2B and B2C businesses.<br /><br />
        But, currently we only accept growth and strategy for <b>digital area</b>
      </div>
    `,
  },
  {
    title: "Can you work with businesses of any size, from startups to large enterprises?",
    content: `
      <div className="text-[#BFBFBF]">
        Yes, we work with businesses of all sizes, from startups to large enterprises. Our services are tailored to meet the unique needs and budgets of each client. Whether you are just starting out or an established organization, we can provide customized growth and experience strategy solutions to fuel your success.
      </div>
    `,
  },
  {
    title: "How do you approach developing a growth and experience strategy?",
    content: `
      <div className="text-[#BFBFBF]">
        We approach developing a growth and experience strategy by conducting in-depth research, understanding your business goals and target audience, analyzing market trends, identifying growth opportunities, defining customer personas, mapping customer journeys, utilizing data-driven insights, leveraging industry best practices, and collaborating closely with your team to align the strategy with your unique business needs.
      </div>
    `,
  },
  {
    title: "How long does it take to develop and implement a growth and experience strategy?",
    content: `
      <div className="text-[#BFBFBF]">
        The duration of developing and implementing a growth and experience strategy depends on the complexity of your business, the scope of the project, and the research and analysis required. It can range from a few weeks to several months.<br /><br />
        We work closely with you to establish a timeline that aligns with your goals and ensures a comprehensive strategy is developed within a reasonable timeframe.
      </div>
    `,
  },
  {
    title: "How do you measure the effectiveness of a growth and experience strategy?",
    content: `
      <div className="text-[#BFBFBF]">
        We use a combination of key performance indicators (KPIs), metrics, and analytics to measure the effectiveness of a growth and experience strategy. These may include customer satisfaction scores, conversion rates, user engagement metrics, and customer retention rates. We set measurable goals upfront and track progress throughout the implementation to evaluate the strategy's impact on your business.
      </div>
    `,
  },
  {
    title: "How do you ensure the strategy is tailored to our specific industry and target audience?",
    content: `
      <div className="text-[#BFBFBF]">
        We conduct in-depth industry research, analyze market trends, and leverage our experience working across various sectors to ensure the strategy is tailored to your specific industry. Additionally, we utilize research methods such as user interviews, persona development, and customer journey mapping to gain a deep understanding of your target audience. This enables us to create a strategy that resonates with your audience's needs, preferences, and pain points.
      </div>
    `,
  },
  {
    title: "Do you offer ongoing support and optimization for the growth and experience strategy?",
    content: `
      <div className="text-[#BFBFBF]">
        Yes, we offer ongoing support and optimization for the growth and experience strategy. We understand that businesses evolve, market conditions change, and customer expectations shift over time.<br /><br />
        Therefore, we provide continuous monitoring, analysis, and optimization to ensure the strategy remains effective and aligned with your evolving business needs. We are committed to your long-term success and offer ongoing support as your trusted growth and experience strategy partner.
      </div>
    `,
  },
];

export const SERVICES_DETAIL = {
  uxr: {
    name: "USER EXPERIENCE RESEARCH",
    heading: "Unleash the power of data-driven research to supercharge your strategy.",
    subHeading: [
      {
        number: "15",
        metrics: "Methodologies Available",
      },
      {
        number: "4+",
        metrics: "Years of Experiences",
      },
      {
        number: "100+",
        metrics: "UX Research Projects",
      },
    ],
    listWorks: LIST_UXR,
    listServices: UXR_SERVICES,
    whatIs: {
      title: "Talk to your users, Capture pain points, Drive actionable improvements.",
      image: require("assets/services/uxr/illustration.png"),
      content: `
        UX Research is a process of gathering and analyzing insights about users, their behaviors, needs, and
        preferences in order to inform and improve the design of products, services, or experiences. <br />
        <br /> By conducting UX research, businesses and organizations can make informed decisions, identify
        opportunities for innovation, and create exceptional user experiences that drive customer satisfaction,
        engagement, and business success.
      `,
    },
    output: UXR_OUTPUT,
    process: {
      title: "Human-centered, Empathetic, and Data-driven",
      image: require("assets/services/uxr/flow.svg").default,
    },
    closingText:
      "Elevate your business by putting the user at the center of your design process and achieve remarkable results",
    faq: UXR_FAQ,
  },
  uxd: {
    name: "EXPERIENCE DESIGN",
    heading: "From Insights to Impact: elevate product with end-to-end experience design.",
    subHeading: [
      {
        number: "4+",
        metrics: "Years of Experiences",
      },
      {
        number: "20+",
        metrics: "Brands Helped",
      },
    ],
    listWorks: LIST_UXD,
    listServices: EXPERIENCE_DESIGN,
    whatIs: {
      title: "Creating intuitive, seamless, and enjoyable product design & experiences for users.",
      image: require("assets/services/uxd/illustration.png"),
      content: `
        User Experience Design is the process of creating products, services, and system that provide meaningful and engaging experiences for users. It is a holistic approach that takes into account the entire user journey, from initial awareness to final interaction.<br /><br />
        If you are looking to create products or services that are successful and engaging, then you should consider experience design. It is a powerful tool that can help you to create experiences that are useful, efficient, emotional, and memorable.
      `,
    },
    output: UXD_OUTPUT,
    process: {
      title: "User-centric, Iterative, and Collaborative",
      image: require("assets/services/uxd/flow.svg").default,
    },
    closingText: "We help businesses achieve remarkable results by designing for people, not machines.",
    faq: UXD_FAQ,
  },
  data: {
    name: "DATA RESEARCH",
    heading: "Uncover insights from data patterns to drive impactful strategies and decisions",
    subHeading: [],
    listWorks: LIST_DATA_RESEARCH,
    listServices: DATA_RESEARCH,
    whatIs: {
      title: "Extract meaningful insights and inform decision-making through data patterns.",
      image: require("assets/services/data/illustration.png"),
      content: `
        Data research refers to the systematic process of collecting, analyzing, and interpreting data to gain insights and inform decision-making.<br /><br />
        The goal of data research is to uncover valuable insights, uncover patterns, validate hypotheses, identify opportunities, solve problems, and support evidence-based decision-making
      `,
    },
    output: DATA_OUTPUT,
    process: {
      title: "Accurate, Relevance, and Timely",
      image: require("assets/services/data/flow.svg").default,
    },
    closingText: "Amplify your brand's presence through enhanced offline experiences.",
    faq: DATA_FAQ,
  },
  offline: {
    name: "OFFLINE EXPERIENCE ENHANCEMENT",
    heading: "Elevating offline experiences with innovative enhancement that leave a mark",
    subHeading: [],
    listWorks: LIST_OFFLINE,
    listServices: OFFLINE_EXPERIENCE_ENHANCEMENT,
    whatIs: {
      title: "Create unique and meaningful engagement strategies in physical spaces.",
      image: require("assets/services/offline/illustration.png"),
      content: `
        Offline experience enhancement refers to the process of improving and optimizing the experiences people have in physical spaces or real-world environments.<br /><br />
        It can include various elements such as spatial design, gamification, sensory engagement, interactive installations, way-finding, and storytelling.
      `,
    },
    output: OFFLINE_OUTPUT,
    process: {
      title: "Personalized, Engaging, Innovative, and Memorable",
      image: require("assets/services/offline/flow.svg").default,
    },
    closingText: "Discover hidden opportunities that drive success through advanced data research.",
    faq: OFFLINE_FAQ,
  },
  growth: {
    name: "GROWTH & EXPERIENCE STRATEGY",
    heading: "Unlocking growth opportunities with data-backed experience strategies",
    subHeading: [],
    listWorks: [],
    listServices: GROWTH_EXPERIENCES_STRATEGY,
    whatIs: {
      title: "A comprehensive plans & strategies that drive business growth in user experiences.",
      image: require("assets/services/growth/illustration.png"),
      content: `
        Growth & experience strategy is a holistic approach to business growth that focuses on both the customer experience and the business's growth goals.<br /><br />
        It is a way of thinking about business growth that goes beyond just increasing sales or market share. Instead, it focuses on creating a positive and memorable customer experience that will drive repeat business and referrals.
      `,
    },
    output: GROWTH_OUTPUT,
    process: {
      title: "Data-driven, Innovative, and Collaborative",
      image: require("assets/services/growth/flow.svg").default,
    },
    closingText: "We help businesses grow by creating amazing experiences for their customers.",
    faq: GROWTH_FAQ,
  },
};
