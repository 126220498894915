import { lazy } from "react";

const HomePage = lazy(() => import("views/home"));
const WorkPage = lazy(() => import("views/work"));
const WorkDetailPage = lazy(() => import("views/work/detail"));
const ServicePage = lazy(() => import("views/services"));
const ServiceDetailPage = lazy(() => import("views/services/detail"));
const ArticlePage = lazy(() => import("views/articles"));
const UnderConstructionPage = lazy(() => import("views/UnderConstruction"));
const NotFoundPage = lazy(() => import("views/404"));

export const routes = [
  {
    element: <HomePage />,
    exact: true,
    path: "/",
  },
  {
    element: <UnderConstructionPage />,
    exact: true,
    path: "/about-us",
  },
  {
    element: <ServicePage />,
    exact: true,
    path: "/services",
  },
  {
    element: <ServiceDetailPage />,
    exact: true,
    path: "/services/detail/:serviceName",
  },
  {
    element: <WorkPage />,
    exact: true,
    path: "/work",
  },
  {
    element: <WorkDetailPage />,
    exact: true,
    path: "/work/detail/:clientName",
  },
  {
    element: <ArticlePage />,
    exact: true,
    path: "/articles",
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },
];
