const Button = ({ id = "", type = "", disabled = false, className = "", onClick = () => {}, children }) => {
  return (
    <button
      className={`btn px-4 py-3 bg-rekursiv-red w-full text-white rounded-3xl h-12 font-bold text-base outline-none cursor-pointer
      active:bg-[#4F1411] hover:bg-[#86221D] duration-200
      disabled:opacity-20 disabled:text-white disabled:cursor-not-allowed
      ${className}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
