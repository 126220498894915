import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "routes";
import MainLayout from "layouts/MainLayout";
import Loader from "components/Loader";
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-NDVZXM2'
}

TagManager.initialize(tagManagerArgs)

function App() {
  const pages = routes.map((route) => (
    <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />
  ));

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route element={<MainLayout />}>{pages}</Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
