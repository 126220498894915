const TextArea = ({ value, placeholder, onChange }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <textarea
          id="textarea"
          className="block w-full px-3 py-2 focus:outline-none border-b-[1px] bg-transparent text-xs lg:text-sm leading-6 text-black placeholder-[#A3A3A3] border-[#BFBFBF]"
          rows={1}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextArea;
