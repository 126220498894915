const InputField = ({ id, name, type = "text", placeholder, value, onChange, className = "", error }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <input
          id={id}
          name={name}
          type={type}
          className={`block w-full px-3 py-2 border-b-[1px] bg-transparent focus:outline-none text-xs lg:text-sm text-black leading-6 placeholder-[#A3A3A3] border-[#BFBFBF] ${className}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {error && <span className="text-xs text-rekursiv-red mt-1">{error}</span>}
      </div>
    </div>
  );
};

export default InputField;
