import { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

const MainLayout = () => {
  const formRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="bg-rekursiv-white">
      <Header formRef={formRef} />
      <Outlet context={[formRef]} />
      <Footer formRef={formRef} />
    </div>
  );
};

export default MainLayout;
