const Chips = ({ label = "", value = "", onClick = () => {}, selected = false }) => (
  <button
    className={`text-xs lg:text-base bg-[#E9E7E3] text-black border-2 border-[#BFBFBF] font-semibold rounded-full duration-200 px-4 py-2 hover:bg-rekursiv-red hover:text-white focus:outline-none focus:ring-2 focus:ring-rekursiv-red active:bg-[#86221D] ${
      selected ? "bg-rekursiv-red ring-rekursiv-red text-white" : ""
    }`}
    onClick={() => onClick(value)}
  >
    {label}
  </button>
);

export default Chips;
