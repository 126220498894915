export const LIST_SOCIAL_MEDIA = [
  {
    name: "Medium",
    link: "https://medium.com/@rekrsv",
    icon: require("assets/icons/footer/socmed/medium.svg").default,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/rekursiv-clifard-sentosa/",
    icon: require("assets/icons/footer/socmed/linkedin.svg").default,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/rekrsv/",
    icon: require("assets/icons/footer/socmed/instagram.svg").default,
  },
];
