import { useLocation, useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

import { LIST_SERVICES } from "config/services";
import { LEARN_ITEMS } from "config/header";
import { LIST_SOCIAL_MEDIA } from "config/footer";

import Form from "./Form";

const year = new Date().getFullYear();

const Footer = ({ formRef }) => {
  // const formRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function scrollToForm() {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const trimPathname = (path) => {
    return path.replace("/", "") || "";
  };

  const isCurrentRoute = (destinationPathname) => {
    return trimPathname(pathname) === destinationPathname;
  };

  const navigatePage = (to) => {
    if (isCurrentRoute(to)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate(`/${to}`);
    }
  };

  const handleSocialMediaIconClick = (url) => {
    window.open(url, "_blank");
  };

  const ListServices = () =>
    LIST_SERVICES.map((item, index) => (
      <div
        key={index}
        onClick={() => navigatePage(trimPathname(item.to))}
        className="mb-3 last:mb-0 block cursor-pointer"
      >
        {item.title}
      </div>
    ));

  const ListPerspectives = () =>
    LEARN_ITEMS.map((item, index) => (
      <div
        key={index}
        onClick={() => navigatePage(trimPathname(item.to))}
        className="mb-3 last:mb-0 block cursor-pointer"
      >
        {item.heading}
      </div>
    ));

  const ListSocialMedia = () => (
    <>
      {LIST_SOCIAL_MEDIA.map((item, index) => (
        <img
          className="cursor-pointer"
          key={index}
          onClick={() => handleSocialMediaIconClick(item.link)}
          src={item.icon}
          alt={`${item.name} icon`}
          loading="lazy"
        />
      ))}
    </>
  );

  return (
    <footer ref={formRef} className="bg-primary pt-16 lg:pt-96 relative">
      <div className="rounded-tl-[120px] bg-rekursiv-red lg:p-4 rounded-tr-3xl lg:rounded-tr-none">
        <div className="static lg:absolute lg:top-32 lg:left-0 lg:right-0 z-10">
          <Form />
        </div>
        <Fade bottom delay={150} fraction={0.1} distance="100px">
          <div className="lg:pt-64 pb-24 lg:pb-[72px]">
            <div className="container pt-16 lg:pt-60 pb-16 lg:pb-24 gap-16 flex justify-between flex-wrap md:flex-nowrap">
              <div className="max-w-[348px]">
                <img
                  className="w-[107px] lg:w-[156px]"
                  src={require("assets/logo-full-white.svg").default}
                  alt="Rekursiv logo white"
                />
                <p className="text-white text-sm lg:text-base mt-5">
                  We specialize in delivering outstanding UX research, strategy, and solutions to help businesses create
                  intuitive and user-centered digital experiences.
                </p>
                <a className="text-white font-bold flex items-center gap-3 my-8" href="mailto:hello-id@rekrsv.com">
                  <span>📧</span>
                  <span className="underline">hello-id@rekrsv.com</span>
                </a>
                <p className="text-white text-sm lg:text-base">
                  The Manhattan Square Mid Tower lt.12 Unit F, Jakarta, Indonesia 12560
                </p>
              </div>
              <div className="flex md:mt-0">
                <div className="flex flex-wrap lg:grid gap-x-6 gap-y-16 lg:grid-cols-3 lg:gap-16">
                  <div className="justify-self-stretch">
                    <h5 className="font-bold text-xs lg:text-sm text-white/60 cursor-default">OUR SERVICES</h5>
                    <nav className="mt-6 text-white cursor-default text-sm whitespace-nowrap">
                      <ListServices />
                    </nav>
                  </div>
                  <div>
                    <h5 className="font-bold text-xs lg:text-sm text-white/60 cursor-default">PERSPECTIVES</h5>
                    <div className="mt-6 text-white cursor-default text-sm">
                      <ListPerspectives />
                    </div>
                  </div>
                  <nav className="cursor-default flex gap-x-6 lg:flex-col">
                    {/* <div
                      onClick={() => navigatePage("about-us")}
                      className="font-semibold text-sm lg:text-2xl text-white lg:mb-3 cursor-pointer"
                    >
                      About Us
                    </div> */}
                    <div
                      onClick={() => navigatePage("work")}
                      className="font-semibold text-sm lg:text-2xl text-white lg:mb-3 cursor-pointer"
                    >
                      Works
                    </div>
                    <div onClick={scrollToForm} className="font-semibold text-sm lg:text-2xl text-white cursor-pointer">
                      Contact Us
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="container flex justify-between text-white text-sm flex-col lg:flex-row">
              <nav className="flex lg:hidden items-center gap-6 lg:gap-8 mb-6">
                <ListSocialMedia />
              </nav>
              <div className="flex justify-between w-full">
                <div className="flex items-center gap-2 lg:gap-3">
                  
                  <span className="text-xs lg:text-base">By</span>
                  <a href="https://antigravity.id/" target="_blank" rel="noreferrer">
                    <img
                      className="w-[82px] lg:w-[120px]"
                      src={require("assets/logo-antigravity.svg").default}
                      alt="Antigravity Logo"
                      loading="lazy"
                    />
                  </a>
                </div>
                <nav className="hidden lg:flex items-center gap-6 lg:gap-8">
                  <ListSocialMedia />
                </nav>
                <span className="text-xs lg:text-base">{year} &copy; PT Rekursiv Clifard Sentosa</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </footer>
  );
};

export default Footer;
