export const PLAYGROUND_ITEMS = [
  {
    heading: "Gamification in Numbers",
    caption: "Interactive ways to visualize data",
    to: "https://antigravity.id/gamification2022",
    openNewTab: true,
    icon: require("assets/icons/navbar/perspectives/gamification.svg").default,
  },
  // { heading: "😎 Coming Soon", caption: "Give us any idea" },
];

export const LEARN_ITEMS = [
  {
    heading: "Rekursiv Special",
    caption: "Our unique activities & special research.",
    to: "/articles#rekursivSpecial",
    icon: require("assets/icons/navbar/perspectives/rekursiv-special.svg").default,
  },
  {
    heading: "UXPlorations",
    caption: "Dive deep into UX-related insights through our mini-research pieces.",
    to: "/articles#uxPlorations",
    icon: require("assets/icons/navbar/perspectives/uxplorations.svg").default,
  },
  {
    heading: "Mastering UX",
    caption: "Get practical tips and techniques in UX.",
    to: "/articles#masteringUX",
    icon: require("assets/icons/navbar/perspectives/mastering-ux.svg").default,
  },
  {
    heading: "Our Creation",
    caption: "See what we have in our creation and tell us",
    to: "/articles#ourCreation",
    icon: require("assets/icons/navbar/perspectives/our-creation.svg").default,
  },
];

export const TOOL_ITEMS = [
  {
    heading: "Respondent Fee Calculator",
    caption: "How much should you pay?",
    icon: require("assets/icons/navbar/perspectives/calculator.svg").default,
  },
  {
    heading: "UX Research Report Kit",
    caption: "Kick off your next study",
    icon: require("assets/icons/navbar/perspectives/report-kit.svg").default,
  },
];
