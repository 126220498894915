export const LIST_UXR = [
  {
    image: require("assets/work/ux-morinaga.png"),
    title: "Enhance Website Experience for Parents with Allergen-sensitive Child",
    key: "morinaga",
    category: "User Experience Research",
  },
  {
    image: require("assets/work/ux-bahana.png"),
    title: "Revitalizing Stock Trading Securities' User Experience",
    key: "bahana",
    category: "User Experience Research",
  },
  {
    image: require("assets/work/ux-lm.png"),
    title: "Revitalizing Music Streaming User Experience",
    key: "lm",
    category: "User Experience Research",
  },
  {
    image: require("assets/work/ux-mirae.png"),
    title: "Coming Soon",
    key: "mirae",
    category: "User Experience Research",
  },
];

export const LIST_UXD = [
  {
    image: require("assets/work/ux-bahana.png"),
    title: "Revitalizing Stock Trading Securities' User Experience",
    key: "bahana",
    category: "Experience Design",
  },
  {
    image: require("assets/work/ux-find-your-spark.png"),
    title: "Navigating Branding Awareness through an Interactive Quiz",
    key: "ntmy",
    category: "Experience Design",
  },
  {
    image: require("assets/work/ux-kalbe.png"),
    title: "Upgrading Website Experience through Chatbot-Inspired User's Assistant",
    key: "kalbe",
    category: "Experience Design",
  },
  {
    image: require("assets/work/ux-morinaga.png"),
    title: "Enhance Website Experience for Parents with Allergen-sensitive Child",
    key: "morinaga",
    category: "Experience Design",
  },
];

export const LIST_DATA_RESEARCH = [
  {
    image: require("assets/work/ux-visualization.png"),
    title: "Unraveling the Impact of Gamification on User Engagement and Retention",
    key: "visual",
    category: "Data Research",
  },
  {
    image: require("assets/work/ux-telkomsel.png"),
    title: "Creating and Improving #JelajahDigital Virtual World",
    key: "telkomsel",
    category: "Data Research",
  },
];

export const LIST_OFFLINE = [
  {
    image: require("assets/work/ux-tarot-bacot.png"),
    title: "Embark on a Journey of Swiping Cards in AR & Personality Discovery",
    key: "tarot",
    category: "Offline Experience Enhancement",
  },
  {
    image: require("assets/work/ux-mot.png"),
    title: "Elevating MoT's Offline Presence through Online Interactive Activities",
    key: "mot",
    category: "Offline Experience Enhancement",
  },
  {
    image: require("assets/work/ux-find-your-spark.png"),
    title: "Navigating Branding Awareness through an Interactive Quiz",
    key: "ntmy",
    category: "Offline Experience Enhancement",
  },
  {
    image: require("assets/work/ux-wristband.png"),
    title: "Optimizing Wristband Distribution with Machines",
    key: "wristband",
    category: "Offline Experience Enhancement",
  },
];

export const LIST_ALL_STUDY_CASE = [...LIST_DATA_RESEARCH, ...LIST_OFFLINE, ...LIST_UXD, ...LIST_UXR];

export const WORK_DETAIL = {
  bahana: {
    category: "USER EXPERIENCE RESEARCH • EXPERIENCE DESIGN",
    title: "Bahana Sekuritas: Revitalizing Stock Trading Securities' User Experience",
    coverImage: require("assets/work/bahana/main.png"),
    overview:
      "Bahana Sekuritas is a well-known online brokerage firm that has been in the market for over a decade. They offer trading services for stocks, mutual funds, and bonds among others through website and mobile application platform. Although they have a loyal customer base, their platform which named Bahana DXTrade, has not seen any major updates in the past few years, and their competitors are gaining an edge with better user experiences. Bahana Sekuritas has noticed a decline in user engagement and wants to revitalize their platform's user experience to retain customers and attract new ones, especially younger generations.",
    goals: [
      "Improve the efficiency, productivity, & end-user satisfaction when using DXTrade",
      "Redesign DXTrade as a multi-asset investment app with modern & user-friendly design",
      "Increase number of transaction value & transaction number by maintaining retention rate",
    ],
    whatWeDid: ["User Experience & Insights", "Experience Design", "User Interface Design"],
    testimony: {
      text: "The agency's UX design work was impressive. They understood our business objectives and user needs, resulting in a visually appealing and user-friendly platform. Their attention to detail and collaboration throughout the process was commendable, leading to increased user engagement and satisfaction. We highly recommend them as a reliable and creative UX design partner",
      author: "Jonathan",
      position: "Head of Development Bahana Sekuritas",
    },
    outcomes: [
      {
        number: "8+",
        metrics: "New Features",
      },
      {
        number: "62%",
        metrics: "Faster Registration",
      },
      {
        number: "92%",
        metrics: "Faster Product Finding",
      },
      {
        number: "<18s",
        metrics: "Time-to-Purchase",
      },
    ],
    keyTakeaway: [
      "Identifying sources of user discomfort",
      "Creating a pain-point-centered idea map",
      "Improve: Redesigning the system",
      "Key features",
    ],
    firstStep: {
      title: "Identifying sources of user discomfort",
      content: `
      <img
        className="w-full"
        src=${require("assets/work/bahana/step-1.png")}
        alt="Identifying sources of user discomfort"
        loading="lazy"
      />
      <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Complicated User Interface</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          Based on our interview with 15 users, we found out that the application has complicated user interface
          which makes it challenging for users to navigate. The application is cluttered, and users have a hard time
          finding relevant information. This complexity imposes a high cognitive load on users, as they need to
          process and remember extensive information. As a result, users are leaving the platform and looking for
          alternative options that offer a better user experience.
        </div>
      </div>
      <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Complex Trading Process</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          The trading process within the app is characterized by complexity, involving multiple steps that users
          must navigate to execute a trade. Users find the process confusing and are uncertain about what they are
          buying or selling. Additionally, redundant menus and confusing options further complicate the process,
          making it challenging to complete straightforward transactions.
        </div>
      </div>
    `,
    },
    secondStep: {
      title: "Creating a pain-point-centered idea map",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/bahana/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-[#BFBFBF] text-base border-[#262525] pt-12 lg:pt-[52px] flex-col lg:flex-row leading-6 lg:leading-7">
            We create a pain-point-centered idea map to identify the challenges or problems that users faces and
            generating potential solutions or ideas to address those pain points. The goal is to create a visual
            representation of the pain points and connect them with relevant ideas to solve or alleviate those
            problems.
          </div>
      `,
    },
    thirdStep: {
      title: "Improve: Redesigning the system",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/bahana/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-[#BFBFBF] text-base border-[#262525] pt-12 lg:pt-[52px] flex-col lg:flex-row leading-6 lg:leading-7">
            By redesigning the system to address users' discomfort with the complicated user interface and complex
            trading process, significant improvements can be achieved. The redesign will focus on enhancing the user
            experience by simplifying the interface, streamlining the trading process, and incorporating user feedback.

            <br /><br />
            Through a clean and intuitive user interface design, clear instructions, and consolidated menus, users will
            find it easier to navigate the application and understand the trading process. Continuous user research and
            feedback collection will ensure ongoing improvement, resulting in a redesigned system that offers a more
            intuitive, efficient, and user-friendly experience, ultimately increasing user satisfaction and confidence
            in the application.
          </div>
    `,
    },
    fourthStep: {
      title: "Key features",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/bahana/step-4-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Effortless Transition Between Financial Instruments
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              As users struggle to differentiate Stock, ESBN, and Mutual Fund menu in Homepage, we've redesigned the
              Bahana DXTrade homepage with user-friendly design that enables clean and easy transition between those
              instruments.
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/bahana/step-4-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Seamless Portfolio Access for Enhanced Monitoring
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              New Bahana DXTrade has streamlined portfolio monitoring by consolidating all relevant information into one
              menu. Users can easily access their asset allocation, instrument portfolio, and profit/loss statement with
              just few clicks.
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/bahana/step-4-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Simplicity to Order with Quick Action in Bottom Navigation Bar
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              New Bahana DXTrade's innovative bottom navigation bar allows for quick and convenient stock purchases,
              enhancing efficiency for traders in the fast-paced market.
            </div>
          </div>
      `,
    },
  },
  wristband: {
    category: "OFFLINE EXPERIENCE ENHANCEMENT",
    title: "FM Reminiscing: Optimizing Wristband Distribution with Machines",
    coverImage: require("assets/work/wristband/main.png"),
    overview:
      "Concert-goers in Jakarta are frustrated with the long and tiring wait times for wristband redemption, which diminishes their experience before the main event even begins. With the upcoming FM reminiscing music event, Antigravity wants to takes the opportunity to improve the wristband redemption process drastically with the implementation of a newly invented distribution machine system that enables users to redeem their wristbands quickly and seamlessly without any human intervention.",
    goals: [
      "Identify pain points and improvements in user's existing wristband redeem journey",
      "Research on how to create a seamless wristband redemption machine experience",
    ],
    whatWeDid: [
      "User Research and insight",
      "Service Design",
      "Strategic Planning",
      "User Interface Design & Prototyping",
    ],
    testimony: {
      text: "Working with Rekursiv on the this project was an exceptional experience. Their dedication, expertise, and commitment to user experience (UX) resulted in outstanding results. Their creative use of technology and deep understanding of UX principles allowed them to develop an efficient wristband distribution system. Rekursiv's attention to detail, effective communication, and rigorous testing ensured a seamless user experience.",
      author: "Adhrian",
      position: "Founder of Antigravity",
    },
    outcomes: [
      {
        number: "1.5k",
        metrics: "Total Redemption",
      },
      {
        number: "<30s",
        metrics: "Distribution Time",
      },
      {
        number: "-50%",
        metrics: "Manpower Assistance",
      },
    ],
    keyTakeaway: [
      "Identifying source of pains points and blockers on existing wristband redemption journey",
      "Creating a pain-point-centered idea map",
      "Wireframing, Designing, Testing, and Producing Machine",
      "Launch!",
    ],
    firstStep: {
      title: "Identifying source of pains points and blockers on existing wristband redemption journey",
      content: `
      <img
        className="w-full"
        src=${require("assets/work/wristband/step-1.png")}
        alt="Identifying sources of user discomfort"
        loading="lazy"
      />
      <p className="text-[#BFBFBF] py-12 lg:py-[52px] leading-6 lg:leading-7 text-base">Through user interview involving 50 concert goers and 5 promotor owners, the research team collected valuable insights into the challenges concert goers and promotors face when redeeming wristbands</p>
      <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">As a concert goers,</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          <ul className="list-disc list-outside pl-5">
            <li className="mt-2 mb-4"><b>Long queue - time consuming</b>. Obtaining a wristband requires waiting in a queue, which typically lasts 30 minutes or longer, particularly on the first day or beforehand</li>
            <li className="mb-4"><b>Redemption period is short</b>. Since redeeming wristbands are time-limited, users are required to visit the designated booth within the timeframe specified by the event organizer. However, this could pose a challenge for some users who may not have the availability to do so.</li>
            <li><b>Low accessibility</b>. Quantifying booth stands in various locations poses a significant challenge, as they require large space typically found only in concert venues. Which means wristband booths only can be accessed in one location.</li>
          </ul>
        </div>
      </div>
      <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">As event promotors,</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          <ul className="list-disc list-outside pl-5">
            <li className="mt-2 mb-4"><b>High manpower cost</b>. Most Wristband distribution table require a minimum of 2 manpower to help distribute the wristband - not to mention the number of additional manpower for different shifts.</li>
            <li><b>Big booth area are costly</b>. Given the short duration of the wristband distribution process, setting up a booth may not be cost-effective, especially when considering the costs associated with booth rental, setup, and tear-down.</li>
          </ul>
        </div>
      </div>
    `,
    },
    secondStep: {
      title: "Creating a pain-point-centered idea map",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/wristband/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-[#BFBFBF] text-base border-[#262525] pt-12 lg:pt-[52px] flex-col lg:flex-row leading-6 lg:leading-7">
            The research team conducted the newly improved customer journey where the purchase online ticket journey will be integrated with the vending machine by using the service design framework to  further streamlining the process and making wristband redeem through vending machines convenient for concert goers.
          </div>
      `,
    },
    thirdStep: {
      title: "Wireframing, Designing, Testing, and Producing Machine",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/wristband/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-[#BFBFBF] text-base border-[#262525] pt-12 lg:pt-[52px] flex-col lg:flex-row leading-6 lg:leading-7">
            Through analysis of the participants' responses, it is evident that while redeeming a wristband without concert staff can be quick, a user-friendly experience similar to purchasing tickets online is crucial to participants. Therefore, to meet this demand, integrating the vending machine into the wristband redemption journey with an intuitive interface to simplify the process and eliminate redundancy is imperative.
            <br /><br />
            To ensure that the vending machine's technology meets the desired standard, a series of rigorous user acceptance testing will be conducted. These tests, created by the developers, will cover various scenarios, such as logging in, ticket validation, IR barcode scanning, and wristband ejection.
          </div>
    `,
    },
    fourthStep: {
      title: "Launch!",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/wristband/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  ntmy: {
    category: "EXPERIENCE DESIGN • OFFLINE EXPERIENCE ENHANCEMENT",
    title: "Nice To Meet You: Navigating Rebranding Awareness through an Interactive Quiz",
    coverImage: require("assets/work/ntmy/main.png"),
    overview:
      "As a result of their recent rebranding, NTMY is looking to take a fresh and unique approach to increase awareness of their brand through a collaboration project with Antigravity. They want to create an interactive experience that is both creative and easily accessible to their target audience. With this strategy, NTMY hopes to leave a lasting impression on their audience and ignite a spark of interest within themselves.",
    goals: [
      "Drive awareness of the newly rebranded NTMY and establish Antigravity as a top-tier developer platform.",
      "Introduce Spark meaning from NTMY main taglines to the audience",
      "Ignite interest and curiosity among the audience.",
    ],
    whatWeDid: ["Concept Ideation", "Story Crafting", "Wireframing"],
    testimony: {
      text: "Working with AG has always been awesome, there are a lot of new unthinkable ideas being talked about here and there. you don't have to worry about the tech limitation as all these ideas are being executed really well and you can see the effort being put into the project. the process is also very enjoyable since everyone is so friendly and creative during every meeting. overall, it has been quite an delightful experience working with AG.",
      author: "Arva",
      position: "Project Manager",
    },
    outcomes: [
      {
        number: "2m 59s",
        metrics: "Session Duration",
      },
      {
        number: "67%",
        metrics: "Share Rate",
      },
      {
        number: "27%",
        metrics: "Bounce Rate",
      },
    ],
    keyTakeaway: [
      "Creating story-driven personality quiz",
      "Set up flow inside the gamification",
      "In-depth design: Mood board, wireframe and user interface",
      "Result: Intuitive personality quiz",
    ],
    firstStep: {
      title: "Creating story-driven personality quiz",
      content: `
      <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
          Ideation Process
        </label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          The ideation phase began with NTMY's intention to launch their rebranding, featuring the theme of "Spark." Both agencies saw an opportunity to align the project with NTMY's rebranding and decided to embrace the "Spark" theme for the personality quiz! This shared theme allowed the two agencies to create a cohesive concept that would resonate with users and showcase the strengths of both AntiGravity and NTMY.
        </div>
      </div>
      <img
        className="w-full rounded-xl"
        src=${require("assets/work/ntmy/step-1.png")}
        alt="Creating a pain-point-centered idea map"
        loading="lazy"
      />
      <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
          Story Exploration
        </label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          We created a simple user journey map to outline the web-based, story-driven personality quiz from start to finish. By mapping out the user experience from the very beginning, the team was able to identify key touch points, interactions, and transitions within the quiz, ensuring a smooth and cohesive experience for users
        </div>
      </div>
    `,
    },
    secondStep: {
      title: "Set up flow inside the gamification",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/ntmy/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-[#BFBFBF] text-base border-[#262525] pt-12 lg:pt-[52px] flex-col lg:flex-row leading-6 lg:leading-7">
            Once we had crafted the story and characters, our team continued to work diligently to develop a detailed user flow that would provide users with a unique and engaging experience. Our approach was to create a flow that aligned perfectly with the story, ensuring that users could fully immerse themselves in the narrative and uncover their persona as they progressed. By designing a flow that was tailored to the needs and desires of our users, we were able to create a truly memorable and engaging experience.
          </div>
      `,
    },
    thirdStep: {
      title: "In-depth design: Mood board, wireframe and user interface",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/ntmy/step-3-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Moodboard
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              The mood board served as a guide and reference for both our UX and UI teams. It was a visual representation of our project's branding, containing elements such as the logo, color scheme, and user interface references. The board was also a place to collect ideas, such as different card designs and how users could interact with them. 
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/ntmy/step-3-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Wireframe
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Our wireframes were designed with careful consideration for the user journey, story, references, and ideas that had been gathered during the project's development.
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/ntmy/step-3-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              Persona Card
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              We've developed a system that helps users identify their sparks by assigning them one of eight different character types. Each of the characters is distinct and unique, with their own set of personality traits and quirks that make them stand out.
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/ntmy/step-3-4.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">
              User Interface
            </label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Converted the wireframe into a detailed and visually stunning high-fidelity design. This design, inspired by the wireframe, incorporated colors, fonts, and graphics to create a realistic and engaging prototype. It served as the foundation for our prototype and development stages, ensuring a seamless user experience.            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "Result: Intuitive personality quiz",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/ntmy/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  tarot: {
    category: "OFFLINE EXPERIENCE ENHANCEMENT",
    title: "Tarot Bacot: Embark on a Journey of Swiping Cards in AR & Personality Discovery",
    coverImage: require("assets/work/tarot/main.png"),
    overview:
      "In an ever-changing world, where the year 2023 holds the promise of new beginnings and uncharted paths, Tarot Bacot emerges as an exciting project by Antigravity. With the aim to increase Antigravity's brand connectivity and engagement towards users, we create an AR gamification that provide users with a glimpse into their life's future.  By leveraging cutting-edge technologies and creative storytelling, Tarot Bacot hopefully able to provide a captivating and memorable experience that resonates with the target audience.",
    goals: [
      "Increase Brand Engagement",
      "Drive Awareness and Interest",
      "Showcase Technological Expertise",
      "Encourage Self-Reflection and Personal Growth",
      "Create a Memorable User Experience",
    ],
    whatWeDid: [
      "Concept Ideation",
      "Personality Profiling",
      "Story Crafting",
      "Wireframing",
      "User Experience Optimization",
    ],
    testimony: {
      text: "We had the pleasure of collaborating with Rekursiv to bring the Tarot Bacot project to life, and it has been an incredible journey. From the initial concept development to the final implementation, Rekursiv demonstrated a deep understanding of our vision and seamlessly integrated it into the project. We look forward to future collaborations and continued success together.",
      author: "Adhrian",
      position: "Founder of Antigravity",
    },
    outcomes: [
      {
        number: "3m 04s",
        metrics: "Session Duration",
      },
      {
        number: "74%",
        metrics: "Share Rate",
      },
      {
        number: "493",
        metrics: "Total Users",
      },
    ],
    keyTakeaway: [
      "Research, Brainstorming, & Concepting: People become intrigued by their fortune as a new year begin.",
      "Personality Profiling: The higher score, The better fortune",
      "Game Mechanics and Wireframing: AR x Gamification x Personality",
      "Testing & Launch!",
    ],
    firstStep: {
      title: "Research, Brainstorming, & Concepting: People become intrigued by their fortune as a new year begin.",
      content: `
      <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Research</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          According to our survey to 100 people in Jakarta Area, 73% of participants believe in luck. This belief in luck is particularly strong among younger Indonesians, with 81,9% of those aged 18-24 believing in luck. As the new year begin, people eagerly seek their fortune as they embrace the new year. This is likely due to a number of factors, including:
          <ul className="list-disc list-outside pl-5">
            <li className="mt-2 mb-4"><b>The desire for a fresh start</b>. The new year is a time for new beginnings, and many people hope that this will be the year that they finally achieve their goals.</li>
            <li className="mb-4"><b>The belief in luck</b>. Many people believe that the new year is a time when luck is on their side, and they may be more likely to take risks or make changes in their lives.</li>
            <li><b>The pressure to succeed</b>. In some cultures, there is a lot of pressure to succeed in the new year, and people may feel like they need to make a big change in order to avoid disappointment.</li>
          </ul>
        </div>
      </div>
      <div className="text-xl lg:text-2xl text-center font-bold leading-6 lg:leading-7 italic pb-12 lg:pb-[52px]">
        How can we create a New Year campaign that maximizes engagement, amplifies brand recognition, and highlights Antigravity's technological expertise?
      </div>
      <img
        className="w-full"
        src=${require("assets/work/tarot/step-1.png")}
        alt="Identifying sources of user discomfort"
        loading="lazy"
      />
      <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
        <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Concepting</label>
        <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
          We investigate the possibilities of AR technology and its potential applications within the project; Identify how AR can enhance user interaction and create immersive experiences; Determine the type of personality test to be integrated into the project.
        </div>
      </div>
    `,
    },
    secondStep: {
      title: "Personality Profiling: The higher score, The better fortune",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/tarot/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Scoring and Performance</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              The user's gameplay performance, such as the accuracy of card swiping and the number of cards successfully swiped is factored into the personality profiling.
            </div>
          </div>
          <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Profiling Results</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Based on the performance, game generates a personalized profile or summary of the user's personality. This profile include descriptions, characteristics, with a fun elements (to show that this is only a joke) related to their personality traits.
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "Game Mechanics and Wireframing: AR x Gamification x Personality",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/tarot/step-3-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Moodboard</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              The moodboard for the Tarot Bacot project aims to combine dreamy, vintage, and sophisticated elements.
              It features soft, pastel colors, ethereal imagery, and elegant typography to create a whimsical and nostalgic atmosphere. The design is clean and polished, with high-quality imagery adding a touch of sophistication.
            </div>
          </div>
          <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Game Mechanics</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              The game mechanics of Tarot Bacot involve a card-swiping gameplay combined with personalized future predictions based on the user's performance. Here's an overview of the game mechanics:
              <ul className="list-decimal list-outside pl-5">
                <li className="mt-2 mb-4">Card-Swiping Gameplay</li>
                <li className="mb-4">Scoring System</li>
                <li className="mb-4">Timer-Based Gameplay</li>
                <li className="mb-4">Difficulty Progression</li>
                <li>Personalized Future Predictions</li>
              </ul>
            </div>
          </div>
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/tarot/step-3-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Persona Card</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              The personality card base idea in Tarot Bacot revolves around their expertise in playing the game, the more mastery, the higher chance to get a pioneer card. Each card is associated with a specific personality characteristic or aspect. There are 10 card that represents different personality traits or archetypes.
            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "Testing & Launch!",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/tarot/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  mot: {
    category: "OFFLINE EXPERIENCE ENHANCEMENT",
    title: "Elevating MoT's Online Presence Through Online Interactive Activities",
    coverImage: require("assets/work/mot/main.png"),
    overview: `Catch The Ghost, developed through a collaboration between the Museum of Toys exhibition, Rekursiv, Antigravity, and Gaspack, combines innovative ideas, gamification technology, and NFT claim technology to create an engaging experience.<br /><br />
      Catch The Ghost is a 3D AR gamification that invites visitors to employ their smartphones for catch virtual ghosts that inside their screens for 15 seconds. With a total of 10 ghosts to collect, participants can enjoy an interactive and fun experience, ultimately earning a special gift from the exhibition and save the NFT into their wallet. This unique approach aims to create hype and provide an immersive engagement for visitors, leaving a lasting impression and igniting their interest in the exhibits.`,
    goals: [
      "Increase user engagement with the MoT Ghostifal exhibition",
      "Provide an immersive and exciting experience for visitors",
      "Encourage visitors to explore the exhibition in a more interactive way",
      "Increase word-of-mouth marketing and virality through social media sharing",
      "Drive users to visit (and revisit) the exhibition through ongoing challenges and rewards.",
    ],
    whatWeDid: [
      "Concept Ideation",
      "Personality Profiling",
      "Story Crafting",
      "Wireframing",
      "User Experience Optimization",
    ],
    testimony: {
      text: "It was a really great collaboration to deliver innovative and interactive web3 game for Ghostival project. The team really understands the customer journey and how to devise a really great customer activation. Not to mention the flawless delivery with such a tight timeline. It was a great experience and will definitely explore more collaboration with them.",
      author: "Novrizal Pratama",
      position: "CEO of Gaspack",
    },
    outcomes: [
      {
        number: "5m 55s",
        metrics: "Session Duration",
      },
      {
        number: "41%",
        metrics: "Bounce Rate",
      },
      {
        number: "∞",
        metrics: "Total Users (On Going)",
      },
    ],
    keyTakeaway: [
      "Connecting all aspects - comprehensive synthesis",
      "How might we use playful Indonesian urban legends & AR to engage curious Gen Z visitors, fostering interactivity, exploration, & participation?",
      "Game mechanisms in the area",
      "Launch!",
    ],
    firstStep: {
      title: "Connecting all aspects - comprehensive synthesis",
      content: `
        <img
          className="w-full"
          src=${require("assets/work/mot/step-1.png")}
          alt="Identifying sources of user discomfort"
          loading="lazy"
        />
        <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">A Playful Twist on Indonesian Urban Legend</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            The ghosts featured in the project are adorable reinterpretations of Indonesian urban legends, presenting a charming and whimsical twist on these traditional tales of the supernatural.
          </div>
        </div>
        <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Gen Z have a high curiosity</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            Gen Z exhibits a greater level of curiosity compared to Millennials. While Millennials may engage with technology-based experiences based on factors such as accessibility and time constraints, Gen Z demonstrates a more open-minded attitude towards new technologies. They are adventurous and readily embrace new experiences, particularly when recommended by their peers.
          </div>
        </div>
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">AR increase interactivity, exploration, & participation</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            A study conducted by Spadoni et al. (2019) found that the integration of AR significantly enhanced visitor engagement, leading to increased levels of interactivity, exploration, and participation. Participants reported a deeper understanding and enjoyment of the museum exhibits, attributing it to the immersive and interactive nature of the AR experience.
          </div>
        </div>
    `,
    },
    secondStep: {
      title:
        "How might we use playful Indonesian urban legends & AR to engage curious Gen Z visitors, fostering interactivity, exploration, & participation?",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/mot/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Augmented Reality (AR) Experiences</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Utilize AR to bring the toys to life, allowing visitors to see virtual ghost animations when viewing exhibits through their smartphones
            </div>
          </div>
          <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Gamification & Interactive Challenges</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Add some gamified elements & interactive challenges to engage visitors. Develop offline to online scavenger hunts that fostering a sense of exploration, competition, and reward.
            </div>
          </div>

          <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Combine Offline & Online Engagement</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              <p className="mb-8">We want to extend user experience beyond the physical visit to create a sense of ownership & exclusivity, enhancing visitor engagement, & amplifying the reach of the Museum of Toys exhibition.</p>
              <ul className="list-disc list-outside pl-5">
                <li className="mt-2 mb-4"><b>Virtual Collectibles</b>. Visitors can acquire ghost cards during their physical visit to the Museum of Toys. They can complete specific challenges to unlock the card & add these virtual collectibles to their digital collection within the platforms.</li>
                <li className="mb-4"><b>NFT Conversion</b>. Visitors also able to convert the virtual collectibles into Non-Fungible Tokens (NFTs).  It creates a sense of exclusivity and allowing for potential future value appreciation.</li>
                <li><b>Shareable Cards through Social Media</b>. Enable seamless integration with social media platforms within the platform. Visitors can directly share their AR experiences or virtual collectibles on their preferred social media channels. It can generate organic exposure & encourage user-generated content.</li>
              </ul>
            </div>
          </div>
          <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Rewards</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Earn collectible virtual cards as a reward for capturing each ghost, to incentivizing participation and creating a sense of achievement.
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "Game mechanisms in the area",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/mot/step-3-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <img
            className="w-full rounded-xl mt-6 lg:mt-[52px]"
            src=${require("assets/work/mot/step-3-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Game Mechanics</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              We Integrate gamification elements into the AR experience, such as challenges to incentivize visitors to actively participate and explore the museum. This can enhance their engagement and create a sense of fun and accomplishment.
              <ul className="list-decimal list-outside pl-5">
                <li className="mt-2 mb-4">Scanning and Collecting</li>
                <li className="mb-4">Interactive AR</li>
                <li className="mb-4">Time-Based Challenge</li>
                <li className="mb-4">Progress Tracking</li>
                <li>Rewards and Incentives</li>
              </ul>
            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "Launch!",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/mot/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  kalbe: {
    category: "EXPERIENCE DESIGN",
    title: "Kalbe Global: Upgrading Website Experience and Usability",
    coverImage: require("assets/work/kalbe/main.png"),
    overview:
      "For a global company, catering to the needs of diverse users from different countries is crucial. Providing a personalized experience that resonates with the target audience is essential to increase brand awareness and drive conversions, especially in foreign markets. As a result, they aim to develop a website that can accommodate those needs.",
    goals: [
      "Increase brand awareness for Kalbe Consumer Health in each of the 9 countries",
      "Drive conversions, particularly in foreign markets",
      "Design a website that caters to the needs of all users, including those with low digital literacy.",
      "Ensuring efficient content management from each countries.",
    ],
    whatWeDid: ["User Research", "User Journey Analysis", "Wireframing", "Usability Testing"],
    outcomes: [
      {
        number: "+-2k",
        metrics: "Avg MAU*",
      },
      {
        number: "2m 03s",
        metrics: "Session Duration",
      },
      {
        number: "4.3%",
        metrics: "External CR to Purchase",
      },
      {
        number: "<20s",
        metrics: "Avg. Searching Time*",
      },
    ],
    keyTakeaway: [
      "Get to know user problems and user needs",
      "The Solution: Kalbe Website",
      "The Solution: Dynamic CMS",
      "We do usability testing for one more time",
      "Launch!",
    ],
    firstStep: {
      title: "Get to know user problems and user needs",
      content: `
        <img
          className="w-full"
          src=${require("assets/work/kalbe/step-1.png")}
          alt="Identifying sources of user discomfort"
          loading="lazy"
        />
        <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Insights Finding Methodology</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            We employ a comprehensive approach to gather insights by utilizing a combination of quantitative and qualitative data through various methods. Specifically, within Kalbe, we conduct in-depth interviews to understand regional user needs. Additionally, we conduct desk research and competitive analysis to gain insights into the website mental models of other countries, given the time constraints of the project.
          </div>
        </div>
        <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Some key takeaways from users</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            From our research, we have identified the top 4 key insights:
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">There is a high demand for guidance in selecting the right medicine among the majority of people.</li>
              <li className="mb-4">Many individuals prefer to seek a quick but comprehensive disease information online (prioritizing efficiency due to time constraints.)</li>
              <li className="mb-4">Country-specific search data highlights queries regarding disease understanding and treatment methods.</li>
              <li>Each individuals from different personas have their own needs in Kalbe company profile website, which is learn more about product, learn more about the company, find a job, and propose a partnership.</li>
            </ul>
            <span className="text-sm text-[#EB8278] inline-block mt-8">*Please note that we have omitted some insights to maintain privacy and confidentiality.</span>
          </div>
        </div>
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Needs from brand</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">Multi-country management to efficiently handle content for different markets</li>
              <li>Efficient flow to cater dynamic layout for each countries website</li>
            </ul>
          </div>
        </div>
    `,
    },
    secondStep: {
      title: "The Solution: Kalbe Website",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/kalbe/step-2-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="mt-6 lg:mt-[52px] flex gap-2 md:gap-4 lg:gap-6 w-auto">
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/kalbe/restructure.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
                />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/kalbe/chatbot.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/kalbe/categories.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/kalbe/contact-us.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
          </div>
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">What We Do</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              <ul className="list-disc list-outside pl-5">
                <li className="mt-2 mb-4"><b>Restructure Menu</b>. We optimize the website's IA to improve navigation and ensure users can easily find the information they need.</li>
                <li className="mb-4"><b>Chatbot Assistant</b>. We integrate a chatbot assistant, such as Kalia, to provide quick and efficient support to users, helping them find what they're looking for with ease.</li>
                <li className="mb-4"><b>Easy-to-find Categories in Brand Page</b>. We organize the brand page into well-defined categories, enabling users to browse and explore specific products or services conveniently.</li>
                <li><b>Easy-to-access Contact Us Form</b>. We design a user-friendly contact form that is easily accessible throughout the website, allowing users to reach out and submit inquiries tailored to their specific needs.</li>
              </ul>
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "The Solution: Dynamic CMS",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/kalbe/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Top 3 Features</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              <ul className="list-disc list-outside pl-5">
                <li className="mt-2 mb-4"><b>Easy Layout Customization</b>. With just a few clicks, administrators can effortlessly change the website's layout. The CMS provides a user-friendly interface that allows for quick and seamless customization, ensuring flexibility in adapting the website's design to meet specific needs or preferences.</li>
                <li className="mb-4"><b>All-in-one Insights</b>. The CMS provides comprehensive insights, including digital engagement metrics and website performance data from each countries. This allows admin to monitor and analyze the effectiveness of the website across different regions.</li>
                <li><b>One-Click Simultaneous Product Publication</b>. This feature streamlines the process and saves time by eliminating the need to individually post products for each country. With this functionality, administrators can ensure consistent and efficient product distribution across multiple markets simultaneously.</li>
              </ul>
            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "We do usability testing for one more time",
      content: `
        <img
          className="w-full rounded-xl"
          src=${require("assets/work/kalbe/step-4-1.png")}
          alt="Creating a pain-point-centered idea map"
          loading="lazy"
        />
        <img
          className="w-full rounded-xl mt-6 lg:mt-[52px]"
          src=${require("assets/work/kalbe/step-4-2.png")}
          alt="Creating a pain-point-centered idea map"
          loading="lazy"
        />
        <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Usability Testing Result</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">Over-information in the homepage</li>
              <li className="mb-4">Difficulty in finding brand categories</li>
              <li className="mb-4">Lack of highlight for chatbot last questions</li>
              <li>Unnoticeable Call-to-Action (CTA) at Ask Kamelia section</li>
            </ul>
          </div>
        </div>
        <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Solution</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">Prioritize key information and remove unnecessary elements</li>
              <li className="mb-4">Make the brand categories more prominently visible</li>
              <li className="mb-4">Ensure the last questions are positioned clearly within the chatbot interface</li>
              <li>Use contrasting colors and prominent placement to make the CTA button stand out</li>
            </ul>
          </div>
        </div>
      `,
    },
    fifthStep: {
      title: "Launch!",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/kalbe/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  morinaga: {
    category: "USER EXPERIENCE RESEARCH • EXPERIENCE DESIGN",
    title: "Morinaga Soya: Enhance Website Experience for Parents with Allergen-sensitive Child",
    coverImage: require("assets/work/morinaga/main.png"),
    overview:
      "Morinaga Soya is a product website catering to parents with allergen-sensitive children. Despite offering necessary information, the website has witnessed a significant decline in traffic over the past 90 days, impacting user acquisition, conversion rates, and causing high bounce rates. To combat this, the company aims to identify the root cause and enhance the website's design to improve user experience.",
    goals: [
      "Boost the number of daily active users on the website",
      "Enhance the website's conversion rate to purchasing process",
      "Minimize the bounce rates on the website",
    ],
    whatWeDid: [
      "Usability Testing",
      "Competitive Analysis",
      "Restructure Information Architecture",
      "Concept Ideation",
      "Wireframing",
      "Product Strategy",
    ],
    outcomes: [
      {
        number: "2m 28s",
        metrics: "Session Duration (+23.74%)",
      },
      {
        number: "2.0",
        metrics: "Page / Session (+22.24%)",
      },
      {
        number: "-3%",
        metrics: "Bounce Rate",
      },
    ],
    keyTakeaway: [
      "Get to know user problems and user needs",
      "The Solution: Morinaga Website",
      "A Glimpse of Before vs After",
    ],
    firstStep: {
      title: "Get to know user problems and user needs",
      content: `
        <img
          className="w-full"
          src=${require("assets/work/morinaga/step-1.png")}
          alt="Identifying sources of user discomfort"
          loading="lazy"
        />
        <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Insights Finding Methodology</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            The insights for the Morinaga Soya website revamp project will be gathered through a comprehensive methodology. This includes conducting <b>usability testing</b> sessions with 5 moms of allergen-sensitive children to observe their interactions and gather feedback. <b>Website analytics and data analysis</b> will be utilized to understand user behavior and identify areas for improvement. <b>Competitor analysis</b> will uncover industry trends and potential gaps.
          </div>
        </div>
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Some key takeaways from users</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            From our research, we have identified the top 5 key insights:
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">The website's navigation confusing and struggled to locate specific information or features.</li>
              <li className="mb-4">Desire for personalized features and recommendations tailored to their child's specific allergen sensitivities.</li>
              <li className="mb-4">Need for more comprehensive and accessible information about allergies.</li>
              <li className="mb-4">Difficulty in quickly finding the right Morinaga Soya product that suits their child's specific allergen sensitivities with current website.</li>
              <li>Difficulty in cooking meals with limited ingredients in refrigerators.</li>
            </ul>
            <span className="text-sm text-[#EB8278] inline-block mt-8">*Please note that we have omitted some insights to maintain privacy and confidentiality.</span>
          </div>
        </div>
    `,
    },
    secondStep: {
      title: "The Solution: Morinaga Website",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/morinaga/step-2-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="mt-6 lg:mt-[52px] flex gap-2 md:gap-4 lg:gap-6 w-auto">
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/morinaga/1.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
                />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/morinaga/2.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/morinaga/3.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
            <div className="w-full">
              <img
                className="rounded-xl w-full"
                src=${require("assets/work/morinaga/4.gif")}
                alt="Creating a pain-point-centered idea map"
                loading="lazy"
              />
            </div>
          </div>
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Top 5 Features</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              <ul className="list-disc list-outside pl-5">
                <li className="mt-2 mb-4"><b>User-friendly Navigation Menu</b>. A seamless and intuitive navigation system with a quick explanation that ensures ease of use and understand for all users. By offering clear menus, intuitive user flows, and well-organized content, it enhances overall usability and reduces frustration, making the website a go-to destination.</li>
                <li className="mb-4"><b>Homepage Question CTA</b>. A prominent & engaging homepage question that asks, "Does your child have allergies?”. This interactive & dynamic approach ensures that users are directed to relevant sections or tools. This targeted approach enhances user engagement, assists in finding relevant information quickly & creates a personalized experience for each user.</li>
                <li className="mb-4"><b>Well Structured Allergy Knowledge Section</b>. Include educational resources, tips for managing allergen sensitivities, guidance on identifying allergenic ingredients, and other relevant information to empower and support parents.</li>
                <li className="mb-4"><b>Auto-recommender Product</b>. An intuitive and user-friendly product search and filtering system that enables parents to quickly find the Morinaga Soya products suitable for their child's specific allergen sensitivities. It is essential for assisting parents in making informed purchasing decisions.</li>
                <li><b>Personalized Recipe Recommendations</b>. A smart tool that generates personalized recipe recommendations based on available ingredients in the user's refrigerator. This time-saving feature reduces food waste and makes cooking easier for moms, providing a strong incentive for them to revisit the website regularly.</li>
              </ul>
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "A Glimpse of Before vs After",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/morinaga/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Before vs After</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              We have implemented multiple changes, some of which are visible above. However, please note that we haven't disclosed all of our modifications. Our revisions encompass various aspects and enhancements to improve the user experiences.
            </div>
          </div>
    `,
    },
  },
  lm: {
    category: "USER EXPERIENCE RESEARCH • EXPERIENCE DESIGN",
    title: "Langit Musik: Revitalizing Music Streaming User Experience",
    coverImage: require("assets/work/lm/main.png"),
    overview:
      "Langit Musik's current content falls short in competing with other music streaming platforms. To address this, Langit Musik aims to revamp its mobile app and introduce new content like podcasts, audiobooks, audio series, live podcasts, and radio. This entails modifying the layout and user experience to accommodate upcoming additions. The research will primarily concentrate on enhancing existing pages related to user exploration, listening, sharing, and monetization. While the new content will retain some similarities to Langit Musik's existing structures, it will also incorporate improvements.",
    goals: [
      "Boost the number of daily active users on the mobile app",
      "Boost engagement during audio listening",
      "Improve premium add ons feature for user's additional experience",
    ],
    whatWeDid: [
      "Usability Testing",
      "Competitive Analysis",
      "Restructure Information Architecture",
      "Concept Ideation",
      "Wireframing",
    ],
    outcomes: [
      {
        number: "+6%",
        metrics: "Engagement Rate",
      },
      {
        number: "+5",
        metrics: "New Audio Content",
      },
      {
        number: "+2",
        metrics: "Revenue Stream",
      },
    ],
    keyTakeaway: [
      "Get to know user problems and user needs",
      "The Solution: Langit Musik Revamp",
      "A Glimpse of Before vs After",
      "Launch!",
    ],
    firstStep: {
      title: "Get to know user problems and user needs",
      content: `
        <img
          className="w-full"
          src=${require("assets/work/lm/step-1.png")}
          alt="Identifying sources of user discomfort"
          loading="lazy"
        />
        <div className="flex text-xl py-12 lg:py-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Insights Finding Methodology</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            The insights for the Langit Musik mobile app revamp project will be gathered through a comprehensive methodology. This includes conducting <b>usability testing</b> sessions with 10 users who explore varieties of audio content to observe their interactions and gather feedback. <b>Website analytics and data analysis</b> will be utilized to understand user behavior and identify areas for improvement. <b>Competitor analysis</b> will uncover industry trends and potential gaps.
          </div>
        </div>
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Some key takeaways from users</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            From our research, we have identified the top 5 key insights:
            <ul className="list-disc list-outside pl-5">
              <li className="mt-2 mb-4">The ability to <b>explore new music and preferred once</b> are difficult to do</li>
              <li className="mb-4"><b>Redundant information on music content</b> on some pages is overwhelming to understand</li>
              <li className="mb-4">Desire to <b>listen and explore different type of audio content</b></li>
              <li className="mb-4">Wants to explore <b>new ways that user can interact more</b> when listening to audio content</li>
              <li>Premium purchase are more desirable when benefits can boost user's experience</li>
            </ul>
            <span className="text-sm text-[#EB8278] inline-block mt-8">*Please note that we have omitted some insights to maintain privacy and confidentiality.</span>
          </div>
        </div>
    `,
    },
    secondStep: {
      title: "The Solution: Langit Musik Revamp",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/lm/step-2-1.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <img
            className="w-full rounded-xl mt-6 lg:mt-[52px]"
            src=${require("assets/work/lm/step-2-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Top 4 Features</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              <ul className="list-disc list-outside pl-5">
                <li className="mt-2 mb-4"><b>Simplify and Filtered Content</b>. Enhance user exploration time by streamlining their thought process when selecting various audio content variations. The chips featured on the homepage serve as a helpful tool, guiding users and creating awareness about new and exciting content options they can enjoy.</li>
                <li className="mb-4"><b>Additional Audio Content</b>. As the demand for non-music content continues to rise, we have curated a comprehensive list of audio content featuring personalized formats and structures. These audio content types can be classified into two groups: on-demand and live content, each offering its own distinct style and catering to specific preferences.</li>
                <li className="mb-4"><b>Engagement through Chat</b>. As content continued to grow, interactions began to adapt accordingly. The engagement between live and on-demand content also differs significantly. With live content, users have the ability to send real-time text messages and gifts, whereas with on-demand content, users can send text messages without real-time constraints.</li>
                <li><b>Purchase Gift, More Loyalty</b>. To encourage regular purchases on the Langit Musik mobile app, users will receive bonuses and benefits each time they send a gift via live chat. The chosen gift will determine the amount of XP the user earns with each send. Accumulating XP will enable users to progress through different loyalty levels, starting from bronze and reaching the highest level, gold. Each achieved level grants access to exclusive rewards available only to that specific level.</li>
              </ul>
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "A Glimpse of Before vs After",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/lm/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Before vs After</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              We have implemented multiple changes, some of which are visible above. However, please note that we haven't disclosed all of our modifications. Our revisions encompass various aspects and enhancements to improve the user experiences.
            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "Launch!",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/lm/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  visual: {
    category: "DATA RESEARCH",
    title: "Data Visualization: Unraveling the Impact of Gamification on User Engagement and Retention",
    coverImage: require("assets/work/visual/main.png"),
    overview:
      "AntiGravity, a leading agency in gamification, has been successfully implementing game strategies for client campaigns throughout 2022. As a part of their continuous improvement, they want to showcase their gamification capabilities, provide insights into each game's performance, and help potential clients select the most suitable option for their needs. To achieve this, they created a comprehensive Data Visualization project that highlights their achievements and findings.",
    goals: [
      "Showcase AntiGravity's game creation capabilities",
      "Provide insights into the performance of each game",
      "Assist potential clients in selecting the most suitable option",
    ],
    whatWeDid: ["Project Concepting", "Data Collection and Analysis", "Data Visualization", "Wireframing"],
    outcomes: [
      {
        number: "300%",
        metrics: "Increase in Number of Leads",
      },
    ],
    keyTakeaway: [
      "Empathizing with Business Stakeholders",
      "Collecting and Processing the Data",
      "Three Main Insights: Audience, Timing, and Business Impact",
      "Making Data Accessible with Interactive Dashboards",
    ],
    firstStep: {
      title: "Empathizing with Business Stakeholders",
      content: `
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">The What, Why, and How</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            Understanding the client's needs is our priority when creating data visualizations. We take time to delve into what <b>business stakeholders are typically looking for</b> when planning to implement gamification. Turns out, they need to understand <b>What</b> kind of games we created in the past, <b>Why</b> they (as a company) need to implement Game and Gamification, and <b>How</b> the implementation will help them achieve their goals.
          </div>
        </div>
    `,
    },
    secondStep: {
      title: "Collecting and Processing the Data",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/visual/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Data Processing</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Our dashboard design process is both thorough and meticulous. We start by <b>compiling data from Google Analytics</b>, which is then <b>processed, cleaned,</b> and <b>analyzed</b> to ensure reliability and accuracy. To craft the charts, we also <b>had a fun time experimenting with D3.JS</b>, a powerful visualization tool, to create dynamic, interactive, and user-friendly interfaces.
              <br /><br />
              The result is an engaging data visualization tool that empowers our clients to easily explore, understand, and make data-driven decisions.
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "Three Main Insights: Audience, Timing, and Business Impact",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/visual/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Insight Breakdown</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              With our deep understanding of stakeholder needs, we reveal comprehensive insights in three core areas.
              Our reports offer a <b>demographic</b> deep dive, provide <b>optimal timeframes</b> for gamification campaigns, and display <b>key business metrics</b> before and after gamification implementation.
              <br /><br />
              These key insights form a holistic overview that demonstrates the power and impact of gamification.
            </div>
          </div>
    `,
    },
    fourthStep: {
      title: "Making Data Accessible with Interactive Dashboards",
      content: `
        <div className="flex items-center justify-center bg-[#292929] rounded-xl py-12 lg:py-[52px]">
          <img
            className="max-h-[400px] lg:max-h-[600px] rounded-xl"
            src=${require("assets/work/visual/result.gif")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
        </div>
      `,
    },
  },
  telkomsel: {
    category: "DATA RESEARCH",
    title: "Telkomsel: Creating and Improving #JelajahDigital Virtual World",
    coverImage: require("assets/work/telkomsel/main.png"),
    overview:
      "Telkomsel is a leading digital telecommunication company providing a unique virtual gamification experience with the Telkomsel Jelajah Digital campaign. In an effort to increase user engagement and brand loyalty, Telkomsel created a dynamic, themed virtual world. The challenge lies in creating an environment that keeps users coming back for more, driving an increase in both new and returning visitors. The virtual world needed to be appealing, interactive, and offer a clear benefit to users.",
    goals: [
      "Increase User Engagement and Retention inside the Virtual World",
      "Improve the conversion rate to Telkomsel's products",
      "An easier way to read and understand #JelajahDigital performance",
    ],
    whatWeDid: ["Gamification Performance Analysis", "Data Analysis", "Craft Data Studio", "Ideas Exploration"],
    outcomes: [
      {
        number: "+251.3%",
        metrics: "Increase New Visitors",
      },
      {
        number: "+300.6%",
        metrics: "Returning Visitors",
      },
      {
        number: "2m 10s",
        metrics: "Avg. Time Spent",
      },
    ],
    keyTakeaway: [
      "Mobile-centric Improvements",
      "Strategic In-game Experience Mapping",
      "Personalized Analytics Dashboard",
    ],
    firstStep: {
      title: "Mobile-centric Improvements",
      content: `
        <div className="flex text-xl pb-12 lg:pb-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Dominated by Mobile Users</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            Our in-depth analysis of the user data revealed that majority of visitors were accessing Telkomsel's virtual world via mobile devices. With this key insight, we focus on enhancing the mobile user experience to better cater to the audience's behavior.
          </div>
        </div>
        <div className="flex text-xl flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
          <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Accommodating for Mobile Experience</label>
          <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
            We improved the interface and interactions to facilitate easy one-hand control. This was accompanied by portrait-oriented content to align with natural phone handling, making it easier for users to engage with the virtual environment. Further to that, we explored the integration of Augmented Reality (AR) engagements, providing a truly immersive and engaging experience.
            <br /><br />
            The goal was not just to accommodate mobile users but to optimize their experience, successfully transforming the way they interacted with Telkomsel's virtual world.
          </div>
        </div>
    `,
    },
    secondStep: {
      title: "Strategic In-game Experience Mapping",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/telkomsel/step-2.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Restructured the Map</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              Just as structuring information on a website is crucial for user experience, strategically designing the in-game map was key to our campaign's success.
              <br /><br />
              We placed Telkomsel's product booths near high-traffic gaming areas, integrating them naturally into the gaming environment. This strategic placement encouraged product discovery as part of the gaming experience, without feeling intrusive or disruptive.
              <br /><br />
              Exploring the impact and possibilities of game element placement was an exciting part of this project, and it proved effective in creating an immersive, engaging environment.
            </div>
          </div>
      `,
    },
    thirdStep: {
      title: "Personalized Analytics Dashboard",
      content: `
          <img
            className="w-full rounded-xl"
            src=${require("assets/work/telkomsel/step-3.png")}
            alt="Creating a pain-point-centered idea map"
            loading="lazy"
          />
          <div className="flex text-xl pt-12 lg:pt-[52px] flex-col lg:flex-row lg:gap-36 leading-6 lg:leading-7">
            <label className="basis-3/12 leading-6 lg:leading-7 text-[#F5F5F5] font-bold mb-4 text-base">Customized Dashboard to Oversee Performance</label>
            <div className="basis-9/12 text-base text-[#BFBFBF] leading-6 lg:leading-7">
              We created a Google Data Studio Dashboard tailored for Telkomsel, providing vital insights on User Overview, Acquisition Overview, In-Website Behavior, and Game Performance. This served as a valuable tool for understanding user behavior and evaluating campaign performance.
              <br /><br />
              This custom tool enabled Telkomsel to effectively track and evaluate the success of their campaign.
            </div>
          </div>
    `,
    },
  },
};
