import { useState } from "react";
import Fade from "react-reveal/Fade";
import { INQUIRY_TYPE, SERVICES_TYPE, SOURCE_TYPE } from "config/form";

import Chips from "./Chips";
import TextArea from "./Input/TextArea";
import InputField from "./Input/InputField";
import Button from "components/Button";

const Form = () => {
  const [formField, setFormField] = useState({
    inquiry: "",
    services: "",
    source: "",
    detail: "",
    name: "",
    companyName: "",
    email: "",
  });

  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleOnChange = (fieldName, value) => {
    setFormField({ ...formField, [fieldName]: value });

    if (fieldName === "email") {
      setIsEmailValid(validateEmail(value));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const openEmail = () => {
    if (formField.email === "") {
      setIsEmailValid(false);

      return
    }

    const { inquiry, services, source, detail, name, companyName, email } = formField;
    const recipientEmail = "hello-id@rekrsv.com";
    const subject = `Inquiry from ${name} - ${companyName}`;
    const body = `Hello Rekursiv Team,
I'm ${name} from ${companyName}.
I'd like to have ${inquiry}${["Consultation", "Quotation"].includes(inquiry) ? ` specifically for ${services}` : ""}.

Here's the detail:
${detail}

For your information, I found your company through ${source}.

You can reach me via ${email}

Thanks!
`;
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body
    )}`;

    window.location.href = mailtoLink;
  };

  const renderChips = (fieldName, chipData) => {
    return (
      <div className="flex gap-2 flex-wrap">
        {chipData.map((item) => (
          <Chips
            key={item.value}
            onClick={(value) => handleOnChange(fieldName, value)}
            label={item.label}
            value={item.label}
            selected={formField[fieldName] === item.label}
          />
        ))}
      </div>
    );
  };

  return (
    <Fade bottom fraction={0.1} distance="20px">
      <div className="bg-[#F0EDE7] py-4 px-6 lg:px-16 container rounded-t-3xl">
        <div className="flex flex-col lg:flex-row">
          <Fade bottom delay={100} fraction={0.1} distance="20px">
            <div className="pt-6 lg:pt-12 pb-4 lg:pb-16 basis-9/12">
              <div className="flex lg:block mb-4 lg:mb-9 justify-between items-center">
                <img
                  className="w-36 lg:w-auto"
                  src={require("assets/illustrations/hello.svg").default}
                  alt="Hello Rekursiv"
                  loading="lazy"
                />
                <img
                  className="w-28 lg:hidden"
                  src={require("assets/illustrations/stamp.svg").default}
                  alt="Stamp"
                  loading="lazy"
                />
              </div>
              <div className="flex xl:items-center flex-col lg:flex-row">
                <span className="text-sm lg:text-xl lg:mr-4 whitespace-nowrap mb-3 lg:mb-0">I'd like to have</span>
                {renderChips("inquiry", INQUIRY_TYPE)}
              </div>
              {["Consultation", "Quotation"].includes(formField.inquiry) && (
                <Fade>
                  <div className="flex items-start mt-5 flex-col lg:flex-row">
                    <span className="text-sm lg:text-xl lg:mr-4 whitespace-nowrap mb-3 lg:mb-0">specifically for</span>
                    {renderChips("services", SERVICES_TYPE)}
                  </div>
                </Fade>
              )}
              <div className="mt-5">
                <div className="text-sm lg:text-xl lg:mr-4 mb-3 lg:mb-9">here is the detail:</div>
                <TextArea
                  placeholder="DETAIL MESSAGES"
                  onChange={(e) => handleOnChange("detail", e.target.value)}
                  value={formField.detail}
                />
              </div>
              <div className="mt-5">
                <div className="text-sm lg:text-xl lg:mr-4 mb-3 lg:mb-5 whitespace-nowrap">
                  for your information, I found your company through
                </div>
                {renderChips("source", SOURCE_TYPE)}
              </div>
            </div>
          </Fade>
          <Fade bottom delay={150} distance="20px">
            <div className="hidden lg:block border-l-2 mx-10 my-16 border-[#BFBFBF]" />
          </Fade>
          <Fade bottom delay={150} fraction={0.1} distance="20px">
            <div className="basis 3/12">
              <img
                className="hidden lg:block"
                src={require("assets/illustrations/stamp.svg").default}
                alt="Stamp"
                loading="lazy"
              />
              <div className="mt-8">
                <InputField
                  id="name"
                  value={formField.name}
                  name="name"
                  placeholder="YOUR NAME"
                  onChange={(e) => handleOnChange("name", e.target.value)}
                />
                <InputField
                  id="companyName"
                  className="mt-6"
                  value={formField.companyName}
                  name="companyName"
                  placeholder="COMPANY NAME"
                  onChange={(e) => handleOnChange("companyName", e.target.value)}
                />
                <InputField
                  id="email"
                  className="mt-6"
                  value={formField.email}
                  name="email"
                  placeholder="EMAIL"
                  onChange={(e) => handleOnChange("email", e.target.value)}
                  error={!isEmailValid && "Please enter a valid email address"}
                />
                <Button
                  className="flex items-center justify-center mb-4 mt-8 lg:my-16"
                  onClick={openEmail}
                  disabled={!isEmailValid}
                >
                  <span className="inline-block mr-2">Send Message</span>
                  <img
                    className="inline-block"
                    src={require("assets/icons/ArrowUpRight.svg").default}
                    alt="Arrow Up Right Icon"
                  />
                </Button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Fade>
  );
};

export default Form;
