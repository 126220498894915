import { Link } from "react-router-dom";
import DropdownItem from "./DropdownItem";

import { PLAYGROUND_ITEMS, LEARN_ITEMS } from "config/header";

const Dropdown = ({ show, onMouseEnter, onMouseLeave }) => {
  if (!show) return null;

  return (
    <div
      className="absolute mt-2 px-8 pt-4 pb-9 bg-primary rounded shadow-md duration-200 h-auto top-10 left-0 right-0 w-screen"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex container gap-24">
        <div>
          <h5 className="font-bold mb-5 uppercase text-[#707070] border-b-[1px] border-[#262525] pb-2 text-sm">
            Playground
          </h5>
          <Link to="https://antigravity.id/gamification2022" target="_blank">
            <img
              className="mb-4 rounded-xl"
              width="440"
              src={require("assets/illustrations/playground.png")}
              alt="Playground"
              loading="lazy"
            />
            <DropdownItem items={PLAYGROUND_ITEMS} />
          </Link>
        </div>
        <div>
          <h5 className="font-bold mb-5 uppercase text-[#707070] border-b-[1px] border-[#262525] pb-2 text-sm">
            Learn
          </h5>
          <DropdownItem items={LEARN_ITEMS} />
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
