export const INQUIRY_TYPE = [
  {
    label: "Quotation",
    value: "quotation",
  },
  {
    label: "Consultation",
    value: "consultation",
  },
  {
    label: "Collaboration",
    value: "collaboration",
  },
  {
    label: "Business Partnership",
    value: "businessPartnership",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const SERVICES_TYPE = [
  {
    label: "User Experience Research",
    value: "userExperienceResearch",
  },
  {
    label: "Data Research",
    value: "dataResearch",
  },
  {
    label: "Growth & Experience Strategy",
    value: "growthAndExperienceStrategy",
  },
  {
    label: "Experience Design",
    value: "experienceDesign",
  },
  {
    label: "Offline Experience Enhancement",
    value: "offlineExperienceEnhancement",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const SOURCE_TYPE = [
  {
    label: "Search Engine (Google)",
    value: "searchEngine",
  },
  {
    label: "Social Media",
    value: "socialMedia",
  },
  {
    label: "Referral",
    value: "referral",
  },
  {
    label: "Online Ads",
    value: "onlineAds",
  },
  {
    label: "Other",
    value: "other",
  },
];
