import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "assets/logo.svg";
import ArrowUpRight from "assets/icons/ArrowUpRight.svg";
import ArrowDown from "assets/icons/ArrowDown.svg";

import Button from "components/Button";
import Dropdown from "./Dropdown";
import MobileHeader from "./Mobile";

const Header = ({ formRef }) => {
  const { pathname } = useLocation();
  const firstStringPathname = pathname?.split("/")[1] || "";
  const [showDropdown, toggleDropdown] = useState(false);

  const handleMouseEnter = () => {
    toggleDropdown(true);
  };

  const handleMouseLeave = () => {
    toggleDropdown(false);
  };

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <header className="h-[68px] lg:h-[92px] py-3 lg:py-6 px-0 bg-primary sticky top-0 border-b-[1px] border-[#1C1C1C] z-50">
        <div className="relative">
          <div className="container flex items-center justify-between">
            <nav className="hidden lg:flex gap-x-9 cursor-pointer text-white">
              <Link
                to="/"
                className={`hover:border-b-rekursiv-red border-b-2 border-b-transparent py-2 duration-200 ${
                  firstStringPathname === "" ? "!border-b-rekursiv-red" : ""
                }`}
              >
                Home
              </Link>

              {/* <Link
                to="/about-us"
                className={`hover:border-b-rekursiv-red border-b-2 py-2 duration-200 poin ${
                  firstStringPathname === "about-us" ? "border-b-rekursiv-red" : "border-b-transparent"
                }`}
              >
                About Us
              </Link> */}
              <Link
                to="/work"
                className={`hover:border-b-rekursiv-red border-b-2 py-2 duration-200 ${
                  firstStringPathname === "work" ? "border-b-rekursiv-red" : "border-b-transparent"
                }`}
              >
                Work
              </Link>
              <Link
                to="/services"
                className={`hover:border-b-rekursiv-red border-b-2 py-2 duration-200 ${
                  firstStringPathname === "services" ? "border-b-rekursiv-red" : "border-b-transparent"
                }`}
              >
                Services
              </Link>
            </nav>
            <div className="hidden lg:block">
              <img src={Logo} alt="Logo Rekursiv" />
            </div>
            <div className="hidden lg:flex gap-x-9 text-white items-center">
              <div className="group" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="cursor-pointer flex gap-x-2">
                  <span
                    className={`group-hover:border-b-rekursiv-red border-b-2 border-b-transparent py-2 duration-200 ${
                      firstStringPathname === "articles" ? "!border-b-rekursiv-red" : "border-b-transparent"
                    }`}
                  >
                    Perspectives
                  </span>
                  <img
                    src={ArrowDown}
                    alt="Arrow Down Icon"
                    className="group-hover:rotate-180 transition-transform duration-200"
                  />
                </div>
                <Dropdown show={showDropdown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
              </div>
              <Button className="flex items-center justify-center" onClick={scrollToForm}>
                <span className="inline-block mr-2">Start a Project</span>
                <img className="inline-block" src={ArrowUpRight} alt="Arrow Up Right Icon" />
              </Button>
            </div>
            <div className="lg:hidden flex justify-between items-center w-full">
              <img className="h-7" src={Logo} alt="Logo Rekursiv" />
              <div>
                <Button className="flex items-center justify-center !py-2 text-xs !h-11" onClick={scrollToForm}>
                  Start a Project
                </Button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileHeader currentPathname={firstStringPathname} />
    </>
  );
};

export default Header;
