import { Link } from "react-router-dom";

const DropdownItem = ({ items }) => {
  return (
    <div className={`${items.length > 1 ? 'lg:grid lg:grid-cols-[repeat(2,_minmax(0,_340px))] lg:gap-x-6 lg:gap-y-8' : ''}`}>
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.to || ""}
          className={`flex items-center gap-x-3 mb-4 lg:mb-0 ${
            item.to ? "cursor-pointer pointer" : "cursor-default pointer-events-none"
          }`}
          target={item.openNewTab ? "_blank" : "_self"}
        >
          <img className={`cursor-pointer`} src={item.icon} alt="Icon" />
          <div>
            <span className="block text-sm lg:text-base text-white">{item.heading}</span>
            <span className="text-[#A3A3A3] lg:text-[#707070] text-xs lg:text-sm">{item.caption}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default DropdownItem;
